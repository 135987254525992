//#region Imports
//Ionic Components
import { IonContent, IonHeader, IonInput, IonButton, IonPage, useIonAlert, IonSpinner } from "@ionic/react"
//Use React
import { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
//Components
import NavBar from "../components/NavBar";
//Axios
import axios from 'axios'
//Resources
import logo from '../resources/logo/logo_transparent_edited.png'
//JS Cookies
import Cookies from 'js-cookie';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
//Style
import './form.css'
//#endregion



const SignUp = () => {

    //#region Variables
    //Routes
    const history = useHistory();
    //Server IP Address
    const serverIP = "recibits.com"
    //Refrences
    const userRef = useRef(null)
    const passRef = useRef(null)
    const emailRef = useRef(null)
    //Input Error
    const [usernameInputError, setUsernameInputError] = useState([])
    const [passwordInputError, setPasswordInputError] = useState([])
    const [emailInputError, setEmailInputError] = useState([])
    const username_rules = [
        { test: (v) => !!v, message: 'UsernameRequired' },
        { test: (v) => v.length >= 3, message: 'UsernameLeastCharacter' },
    ]
    const email_rules = [
        { test: (v) => !!v, message: 'EmailRequired' }, // Ensure the input is not empty
        { 
            test: (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v), 
            message: 'EmailIncorrect'
        },
    ];
    const password_rules = [
        { test: (v) => !!v, message: 'PasswordRequired' },
        { test: (v) => !/\s/.test(v), message: 'PasswordSpace' },
        { test: (v) => v.length >= 8, message: 'PasswordLeastCharacter' },
        { test: (v) => /[A-Z]/.test(v), message: 'PasswordCapital' },
        { test: (v) => /[a-z]/.test(v), message: 'PasswordSmall' },
        { test: (v) => /\d/.test(v), message: 'PasswordNumber' },
        { test: (v) => /[!@#$%^&*(),.?":{}|<>]/.test(v), message: 'PasswordSpecial' },
    ]
    //Process Checker
    const [signingUp, setSigningUp] = useState(false)
    //Alert
    const [signupAlert] = useIonAlert();
    //Language Data
    const [lang,setLang] = useState("en")
    const resources = {
        en: {
            translation: {
                "undefined": "",
                //Input Labels
                "Username": "Username",
                "Email": "Email",
                "Password": "Password",
                //Input Placeholders
                "EUsername": "Enter username",
                "EEmail": "Enter Email",
                "EPass": "Enter password",
                //Sign up button
                "SignUp": "SignUp",
                //Links
                "AAccount": "Already have an account?",
                //Errors
                "UsernameRequired": "Username required!",
                "UsernameLeastCharacter": "Must be at least 3 characters.",
                "EmailRequired": "Email is required.",
                "EmailIncorrect": "Must be a valid email address.",
                "PasswordRequired": "Password required!",
                "PasswordSpace": "No spaces allowed.",
                "PasswordLeastCharacter": "Must be at least 8 characters.",
                "PasswordCapital": "Must include at least one uppercase letter.",
                "PasswordSmall": "Must include at least one lowercase letter.",
                "PasswordNumber": "Must include at least one number.",
                "PasswordSpecial": "Must include at least one special character.",
            },
        },
        ar: {
            translation: {
                "undefined": "",
                //Input Labels
                "Username": "الاسم",
                "Email": "بريد الكتروني",
                "Password": "كلمة المرور",
                //Input Placeholders
                "EUsername": "ادخل الاسم",
                "EEmail": "ادخل البريد الالكتروني",
                "EPass": "ادخل كلمة المرور",
                //Sign up button
                "SignUp": "انشاء الحساب",
                //Links
                "AAccount": "لديك حساب؟",
                //Errors
                "UsernameRequired": "اسم المستخدم مطلوب!",
                "UsernameLeastCharacter": "يجب أن يكون على الأقل ٣ أحرف.",
                "EmailRequired": "البريد الإلكتروني مطلوب.",
                "EmailIncorrect": "يجب أن يكون عنوان بريد إلكتروني صالحًا.",
                "PasswordRequired": "كلمة المرور مطلوبة!",
                "PasswordSpace": "لا مسافات مسموح بها.",
                "PasswordLeastCharacter": "يجب أن يكون على الأقل ٨ أحرف.",
                "PasswordCapital": "يجب أن تتضمن حرفًا كبيرًا واحدًا على الأقل.",
                "PasswordSmall": "يجب أن تتضمن حرفًا صغيرًا واحدًا على الأقل.",
                "PasswordNumber": "يجب أن تتضمن رقمًا واحدًا على الأقل.",
                "PasswordSpecial": "يجب أن تتضمن حرفًا خاصًا واحدًا على الأقل.",

        },
    },
    };
    i18n.use(initReactI18next).init({
        resources,
        lng: localStorage.getItem('language') || lang, // Default language
        keySeparator: false,
        interpolation: { escapeValue: false },
    });
    //#endregion

    //#region Functions
    //Language Set From Cookies
    const onLangClick = () => {
        if(lang == "en"){
            setLang("ar")
            Cookies.set('language', "ar", { expires: 365, path: '/' });
        }
        else{
            setLang("en")
            Cookies.set('language', "en", { expires: 365, path: '/' });
        }
    }
    //Validate Inputs
    const validateUsername = (username) => {
        const failedUsernameRules = username_rules.filter((rule) => !rule.test(username)).map((rule) => rule.message);
        setUsernameInputError(failedUsernameRules);
    }
    const validatePassword = (password) => {
        const failedPasswordRules = password_rules.filter((rule) => !rule.test(password)).map((rule) => rule.message);
        setPasswordInputError(failedPasswordRules);
    }
    const validateEmail = (email) => {
        const failedEmailRules = email_rules.filter((rule) => !rule.test(email)).map((rule) => rule.message);
        setEmailInputError(failedEmailRules);
    }
    //Checking Username
    const checkUsername = async (username) => {
        console.log("checking username...")
        try{
            return await axios.get(`https://${serverIP}/api/checkUs?username=${encodeURIComponent(username)}`,{withCredentials: true })
            .then((result) => {
                    if(result.data.length == 0){
                        console.log("middle")
                        return(1)
                    }
                    else {
                        signupAlert({
                            header: lang=='en'?"Alert":"تنبيه",
                            message:  lang=='en'?'Username Already Exists':'اسم المستخدم موجود مسبقاً',
                            buttons: [lang=='en'?"Ok":"نعم"],
                            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                            mode: 'md',
                        })
                        return false
                    } 
            })
        }catch(error){
            if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
                const trial = await fetchCsrfToken(); // Refresh the token
                if(!trial){
                    signupAlert({
                        header: lang=='en'?"ُError!":"!خطأ",
                        message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
                        buttons: [lang=='en'?"Ok":"نعم"],
                        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                        mode: 'md',
                    })  
                }else{
                    // Retry the request after refreshing the token
                    return await checkUsername(username);
                }
            }else{
                signupAlert({
                    header: lang=='en'?"ُError!":"!خطأ",
                    message:  lang=='en'?'An Error occured signing you up! Please try again.':'حدث خطأ أثناء تسجيلك! يرجى المحاولة مرة أخرى.',
                    buttons: [lang=='en'?"Ok":"نعم"],
                    cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                    mode: 'md',
                })
                return false
            } 
        }
    }
    //Checking Email
    const checkEmail = async(email) => {
        console.log("checking email...")
        try{
            return await axios.get(`https://${serverIP}/api/checkEmail?email=${encodeURIComponent(email)}`,{withCredentials: true })
            .then((result) => {
                if(result.data.length == 0){
                    return true
                }
                else{
                    signupAlert({
                        header: lang=='en'?"Alert":"تنبيه",
                        message:  lang=='en'?'Email Already Exists':'البريد الإلكتروني موجود مسبقاً',
                        buttons: [lang=='en'?"Ok":"نعم"],
                        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                        mode: 'md',
                    })
                    return false
                }     
            })
        }catch(error){
            if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
                const trial = await fetchCsrfToken(); // Refresh the token
                if(!trial){
                  signupAlert({
                    header: lang=='en'?"ُError!":"!خطأ",
                    message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
                    buttons: [lang=='en'?"Ok":"نعم"],
                    cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                    mode: 'md',
                  })  
                }else{
                  // Retry the email check
                return await checkEmail(email);
                }
                
            }else{
                signupAlert({
                    header: lang=='en'?"ُError!":"!خطأ",
                    message:  lang=='en'?'An Error occured signing you up! Please try again.':'حدث خطأ أثناء تسجيلك! يرجى المحاولة مرة أخرى.',
                    buttons: [lang=='en'?"Ok":"نعم"],
                    cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                    mode: 'md',
                })
                return false
            }
        }
    }
    //Sign up the user
    const signUserUp = async (username, password, email) => {
        try{
            const result = {
                email: email,
                username: username,
                password: password
            }
            return await axios.post(`https://${serverIP}/api/InsertData`,result,{withCredentials: true })  
            .then(res => {
                return true
            })
        }catch(error){
            if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
                const trial = await fetchCsrfToken(); // Refresh the token
                if(!trial){
                    signupAlert({
                        header: lang=='en'?"ُError!":"!خطأ",
                        message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
                        buttons: [lang=='en'?"Ok":"نعم"],
                        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                        mode: 'md',
                    })  
                }else{
                    // Retrysigning up
                    return await signUserUp(username, password, email);
                }
                
            }else{
                signupAlert({
                    header: lang=='en'?"ُError!":"!خطأ",
                    message: lang=='en'?'An Error occured signing you up! Please try again.':'حدث خطأ أثناء تسجيلك! يرجى المحاولة مرة أخرى.',
                    buttons: [lang=='en'?"Ok":"نعم"],
                    cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                    mode: 'md',
                })
                return false
            }
        }
    }
    //send verify link
    const verifyUser = async (email) => {
        const result = {
            email: email
        }
        try{
            axios.post(`https://${serverIP}/api/verifyRequest`,result,{withCredentials: true })
        }catch(error){
            if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
                const trial = await fetchCsrfToken(); // Refresh the token
                if(!trial){
                  signupAlert({
                    header: lang=='en'?"ُError!":"!خطأ",
                    message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
                    buttons: [lang=='en'?"Ok":"نعم"],
                    cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                    mode: 'md',
                  })  
                }else{
                  // Retrysigning up
                    verifyUser(email);
                }
                
            }
        }
    }
    //log user in
    const logUserIn = async(username, password) => {
        try{
            console.log("ok")
            await axios.post(`https://${serverIP}/api/login`, {username, password}, { withCredentials: true })
                .then(async (result) => {
                    console.log(result)
                    if(result.data.length == 0){
                        
                        //Sent to log in page in case an error occured
                        signupAlert({
                            header: lang=='en'?"ُError!":"!خطأ",
                            message: lang=='en'?"An Error occured logging you in! Please Login.  If you didn't receive a verification email, you can always request it from the home page":'حدث خطأ أثناء تسجيل دخولك! الرجاء تسجيل الدخول.  إذا لم تتلق رسالة التحقق عبر البريد الإلكتروني، فيمكنك دائمًا طلبها من الصفحة الرئيسية',
                            buttons: [
                            {
                                text: `${lang=='en'?"Ok":"نعم"}`,
                                handler: () => {
                                    history.replace('/login')
                                }
                            }],
                            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                            mode: 'md',
                        })
                    }
                    //sent to home page if logged in
                    else{   
                        signupAlert({
                            header: lang=='en'?'Done!':"!انتهى",
                            message: lang=='en'?"You will be logged in! Please verify your email. If you didn't receive a verification email, you can always request it from the home page":'سيتم تسجيل دخولك! يرجى التحقق من البريد الإلكتروني الخاص بك. إذا لم تتلق رسالة التحقق عبر البريد الإلكتروني، فيمكنك دائمًا طلبها من الصفحة الرئيسية',
                            buttons: [lang=='en'?"Ok":"نعم"],
                            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                            mode: 'md',
                        })          
                        history.replace({
                            pathname: '/Home'
                        })
                    }       
                })
        }catch(error){
            if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
                const trial = await fetchCsrfToken(); // Refresh the token
                if(!trial){
                  signupAlert({
                    header: lang=='en'?"ُError!":"!خطأ",
                    message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
                    buttons: [lang=='en'?"Ok":"نعم"],
                    cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                    mode: 'md',
                  })  
                }else{
                    // Retrysigning up
                    logUserIn(username, password);
                }
            }else{
                signupAlert({
                    header: lang=='en'?"ُError!":"!خطأ",
                    message: lang=='en'?"An Error occured logging you in! Please Login.  If you didn't receive a verification email, you can always request it from the home page":'حدث خطأ أثناء تسجيل دخولك! الرجاء تسجيل الدخول.  إذا لم تتلق رسالة التحقق عبر البريد الإلكتروني، فيمكنك دائمًا طلبها من الصفحة الرئيسية',
                    buttons: [
                        {
                        text: `${lang=='en'?"Ok":"نعم"}`,
                        handler: () => {
                            history.replace('/login')
                        }
                        }],
                    cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                    mode: 'md',
                })
            } 
        }  
    }
    //Perform Signing up Procedure
    const signUpProcess = async (username, password, email) => {
        //Check Input Validity
        if(username==''){
            signupAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?'Please Enter a Username to proceed!':'الرجاء إدخال اسم مستخدم للمتابعة!',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
            validateUsername('')
            return
        }
        if(usernameInputError.length>0){
            signupAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?'The Username you typed is not acceptable!':'اسم المستخدم الذي كتبته غير مقبول!',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
            return
        }
        if(email==''){
            signupAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?'Please Enter an Email to proceed!':'الرجاء إدخال البريد الإلكتروني للمتابعة!',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
            validateEmail('')
            return
        }
        if(emailInputError.length>0){
            signupAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?'The Email you typed is not acceptable!':'البريد الإلكتروني الذي كتبته غير مقبول!',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
            return
        }
        if(password==''){
            signupAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?'Please Enter a Password to proceed!':'الرجاء إدخال كلمة المرور للمتابعة!',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
            validatePassword('')
            return
        }
        if(passwordInputError.length>0){
            signupAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?'The Password you typed is not acceptable!':'كلمة المرور التي كتبتها غير مقبولة!',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
            return
        }   
        //Start Sign up process 
        setSigningUp(true)
        const usernameChecked = await checkUsername(username)
        const emailChecked = await checkEmail(email)
        if(usernameChecked && emailChecked){
            const signedUp = await signUserUp(username, password, email)
            if(signedUp){
                verifyUser(email)
                logUserIn(username, password)
            }
        }
        setSigningUp(false)
    }
    //CSRF server Request
    const fetchCsrfToken = async () => {
        try {
        await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true}).then(()=>{
            return 1
        });
        } catch (error) {
        //Error Handling
        return 0
        }
    };
    //#endregion

    //#region Use Effect
    useEffect(()=>{

        // Access Token
        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
                .then(response => {
                    //Token Response
                })
                .catch(error => {
                    //Handle Error
                });
        }, 55 * 60 * 1000); // Refresh the token every 55 minutes (before expiry)
            
        // Access Token Refresh
        const authenticateAPI = async () => {  
            await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
            .then(response => {
                //Token Response
            })
            .catch(error => {
                //Handle Error
            });
        }

        //Check cookies for log in info
        const getProtectedResource = async () => {
            try {
            await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true }).then(()=>{
                history.replace({pathname: '/home'})
            });
            } catch (error) {
            if (error.response && error.response.status === 401) { // Auth Cookie not found
                try {
                await axios.post(`https://${serverIP}/api/refresh`, {}, { withCredentials: true  });
                await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true }).then(()=>{
                    history.replace({pathname: '/home'})
                });
                } catch (refreshError) { // No refresh cookie found
                    //Handle Error
                }
            }
            }
        };

        const loadData = async () => {
            setLang(Cookies.get('language') || 'en')
            await fetchCsrfToken()
            await authenticateAPI()
            getProtectedResource()
        }
        
        loadData()

        return () => {
            clearInterval(intervalId); 
        };


    },[])
    //#endregion

    return(
        <IonPage>
            <IonHeader className='form_header'>
                <NavBar lang={lang} onLangClick={onLangClick} ></NavBar>
            </IonHeader>
            <IonContent>
                <div className='form_background'></div>
                <div className='form_background_img'></div>
                <div className="form_main form_main_signup">
                    <div className="form ">
                        <img src={logo} className="form_logo"></img>
                        <IonInput
                            mode="md"
                            style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}
                            ref={userRef} 
                            className="ion-invalid ion-touched form_input" 
                            label={t('Username')} 
                            labelPlacement="floating" 
                            fill="outline" 
                            placeholder={t('EUsername')} 
                            errorText={t(`${usernameInputError[0]}`)}
                            onIonInput={(e)=>validateUsername(e.detail.value || '')}
                            required
                        />
                        <IonInput
                            mode="md"
                            style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}
                            ref={emailRef} 
                            className="ion-invalid ion-touched form_input" 
                            label={t('Email')} 
                            labelPlacement="floating" 
                            fill="outline" 
                            placeholder={t('EEmail')}
                            errorText= {t(`${emailInputError[0]}`)}
                            onIonInput={(e)=>validateEmail(e.detail.value || '')} 
                            required
                        />
                        <IonInput 
                            mode="md"
                            style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}
                            ref={passRef} 
                            className="ion-invalid ion-touched form_input" 
                            label={t('Password')} 
                            type="password" 
                            labelPlacement="floating" 
                            fill="outline" 
                            placeholder={t('EPass')} 
                            helperText="Enter Your Password"
                            errorText= {t(`${passwordInputError[0]}`)}
                            onIonInput={(e)=>validatePassword(e.detail.value || '')}
                            required
                        />
                        {!signingUp ?
                            <IonButton mode="md" className="form_btn signup_btn" onClick={() => signUpProcess(userRef.current.value, passRef.current.value, emailRef.current.value)}>{t('SignUp')}</IonButton>
                            :
                            <IonButton mode="md" className="form_btn"><IonSpinner name='circular' className='form_spinner'/></IonButton>
                        }
                        <div className="form_tags signup_tags">
                            <a className="form_tag signup_tag" href = "/Login">{t('AAccount')}</a> 
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )}



export default SignUp;