//#region Imports
//Ionic Components
import { IonContent, IonHeader, IonPage, IonSearchbar, IonGrid, IonCol, IonRow, IonImg, IonButton, useIonAlert, IonSelect, IonSelectOption, IonSpinner } from '@ionic/react';
//Use React
import { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
//Components
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import RecipeCard from '../components/RecipeCard';
import PremiumReciCard from '../components/premiumRecipeCard'
//Classes
import Recipe from '../components/Recipe';
//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
//Function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
//Swiper styles
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import "swiper/css";
import "swiper/css/navigation";
//Axios
import axios from 'axios'
//Resources
import one from "../resources/try14.jpg"
import two from "../resources/try19.png"
import three from "../resources/try20.png"
import four from "../resources/try21.png"
import crown from "../resources/crown.png"
//JS Cookies
import Cookies from 'js-cookie';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
//Style
import './Home.css';
//#endregion

// register Swiper custom elements
register();

const Home = () => {

  //#region Variables
  //Routes
  const history = useHistory();
  const location = useLocation()
  //Server IP Address
  const serverIP = "recibits.com"
  //References
  const categoryRef = useRef(null)
  //Process checker
  const [isLoading, setLoading] = useState(true)
  const [listLoading, setListLoading] = useState(false)
  //Alert
  const [presentAlert] = useIonAlert();
  //Language Data
  const [lang, setLang] = useState("en")
  const resources = {
    en: {
      translation: {
        "Highlights": "Highlights",
        "None": "None",
        "Category": "Category",
        "Favourite": "Favourite",
        "No Results": "No results found",
        "Search": "Search",
        "Alert" : "Alert",
        "Premium" : "Premium",
      },
    },
    ar: {
      translation: {
        "Highlights": "أبرز الوصفات",
        "None": "لا شيء",
        "Category": "فئة",
        "Favourite": "المفضل",
        "No Results": "لم يتم العثور على نتيجة",
        "Search": "بحث",
        "Alert" : "تنبيه",
        "Premium" : "مميزة",
      },
    },
  };
  i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || lang, // Default language
    keySeparator: false,
    interpolation: { escapeValue: false },
  });
  //Window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  //States
  //Lists
    //Recipe List
    const [recipeList, setRecipeList] = useState([])
    //Category List
    const [categories,setCategories] = useState([])
    //Fav List
    const [favouriteList, setFav] = useState([]);
    //Highlight Recipe List
    const [recentRecipes, setRecentRecipes] = useState([])
  //Filtering Recipes
    //No selected category => all included
    const [allCategories, setAllCategories] = useState(true);
    //Set category
    const [selectedCategory, setSelectedCategory] = useState(undefined)
    //Set Filter
    const [usedFilter, setFilter] = useState(0)
    //Set Search field
    const [searchData, setSearchData] = useState('');
    //Set fav
    const [favPressed, setfavPressed] = useState(false)
  //User Info
    //is logged in?
    const [isLoggedin, setisLoggedin] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    //is premium?
    const [isPremium, setUserPremium] = useState(false)
    //is verified?
    const [verified, setVerified] = useState(false)
    //username
    const [username, setUsername] = useState('')
    //email
    const [userEmail, setUserEmail] = useState('')
  //#endregion

  //#region Functions
  //Login
  const onLoginClick = () => {
    history.push({
      pathname: '/login'
    })
  }
  //Language Set From Cookies
  const onLangClick = () => {
    if(lang == "en"){
      setLang("ar")
      Cookies.set('language', "ar", { expires: 365, path: '/' });
    }
    else{
      setLang("en")
      Cookies.set('language', "en", { expires: 365, path: '/' });
    } 
  }
  //Subscribe
  const onSubscribeClick = () => {
      history.push({
        pathname: '/premium'
      })
  }
  //Logout
  const onLogout = async () => {
    await axios.post(`https://${serverIP}/api/logout`, {}, { withCredentials: true })
    .then(()=>{
      setisLoggedin(false)
      setShowAccount(false)
      setfavPressed(false)
      setFav([])
      setUserPremium(false)
      history.replace({
        pathname: '/'
      })
    })
    .catch(async error =>{
      if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
        const trial = await fetchCsrfToken(); // Refresh the token
        if(!trial){
          presentAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })  
        }else{
          // Retry the request after refreshing the token
          onLogout();
        }
      }else{
        presentAlert({
          header: lang=='en'?"ُError!":"!خطأ",
          message:  lang=='en'?'An unexpected error Occured logging out!':'حدث خطأ غير متوقع أثناء تسجيل الخروج!',
          buttons: [lang=='en'?"Ok":"نعم"],
          cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
          mode: 'md',
        })
      }
    });
  }
  //Choose Recipe
  const onClickRecipe = (id) => {
    if(!favPressed){
      if(recipeList[id].getIsPremium() && !isPremium){
        presentAlert({
          header: lang=='en'?"Alert":"تنبيه",
          message: lang=='en' ? "You have to be subscribed to check Premium Recipes": 'يجب أن تكون مشتركًا للتحقق من الوصفات المميزة',
          buttons: [
            {
              text: `${lang=='en' ? 'Cancel' : 'إلغاء'}`,
              role: 'cancel',
              handler: () => {
              // Handle cancel action
              }
            },
            {
              text: `${lang=='en' ? 'subscribe' : 'إشتراك'}`,
              handler: () => {
                history.push({
                  pathname: '/premium'
                })
              }
            }
            ],
          cssClass: lang=='en' ? 'home_alert' : 'home_alert home_alert_arabic'
        })
        return
      }
      history.push({
          pathname: `/recipe/${recipeList[id].getrId()}/${encodeURIComponent(recipeList[id].getTitle())}`
      });
    }else{
      if(favouriteList[id].getIsPremium() && !isPremium){
        presentAlert({
          header: lang=='en'?"Alert":"تنبيه",
          message: lang=='en' ? "You have to be subscribed to check Premium Recipes": 'يجب أن تكون مشتركًا للتحقق من الوصفات المميزة',
          buttons: [
            {
              text: `${lang=='en' ? 'Cancel' : 'إلغاء'}`,
              role: 'cancel',
              handler: () => {
              // Handle cancel action
              }
            },
            {
              text: `${lang=='en' ? 'subscribe' : 'إشتراك'}`,
              handler: () => {
                history.push({
                  pathname: '/premium'
                })
              }
            }
            ],
          cssClass: lang=='en' ? 'home_alert' : 'home_alert home_alert_arabic'
        })
        return
      }
        history.push({
          pathname: `/recipe/${favouriteList[id].getrId()}/${encodeURIComponent(favouriteList[id].getTitle())}`
      });
      }
  }
  //Choose Recent Recipe
  const onClickRecentRecipe = (id) => {
    if(isPremium){
    history.push({
      pathname: `/recipe/${recentRecipes[id].getrId()}/${encodeURIComponent(recentRecipes[id].getTitle())}`
    });
    }else{
      presentAlert({
        header: lang=='en'?"Alert":"تنبيه",
        message: lang=='en' ? "You have to be subscribed to check Premium Recipes": 'يجب أن تكون مشتركًا للتحقق من الوصفات المميزة',
        buttons: [
          {
            text: `${lang=='en' ? 'Cancel' : 'إلغاء'}`,
            role: 'cancel',
            handler: () => {
            // Handle cancel action
            }
          },
          {
            text: `${lang=='en' ? 'subscribe' : 'إشتراك'}`,
            handler: () => {
              history.push({
                pathname: '/premium'
              })
            }
          }
          ],
        cssClass: lang=='en' ? 'home_alert' : 'home_alert home_alert_arabic'
      })
    }
  }
  //Filter Recipes
  const filter = (id) => {
    setListLoading(true)
    setTimeout(()=>{
      if(id == ""){
        setAllCategories(true)
        setFilter(0)
      }else{
        setAllCategories(false)
        setFilter(id)
      }
      setListLoading(false)
    }, 500)
  }
  //Search 
  const setSearch = (e) => {
    setSearchData(e.detail.value)
  }
  //Favourite Only
  const setFavourite = () => {
    if(favPressed){
      setfavPressed(false)
    }else{
      setfavPressed(true)
    }
  }
  //send verify link
  const verifyUser = async () => {
    if(!userEmail){
      return
    }
    const result = {
        email: userEmail
    }
    try{
        axios.post(`https://${serverIP}/api/verifyRequest`,result,{withCredentials: true }).then(()=>{
          presentAlert({
            header: lang=='en'?'Done!':"!انتهى",
            message:  lang=='en'?"If the email exists, a verification email has been sent.":'إذا كان البريد الإلكتروني موجودًا، فقد تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور.',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })
        })
    }catch(error){
        if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
          const trial = await fetchCsrfToken(); // Refresh the token
          if(!trial){
            presentAlert({
              header: lang=='en'?"ُError!":"!خطأ",
              message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
              buttons: [lang=='en'?"Ok":"نعم"],
              cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
              mode: 'md',
            })  
          }else{
            // Retrysigning up
            verifyUser();
          }
        }
    }
  }
  //Get All Visible Recipes
  const getRecipes = async () => {
    let tempRecipeList = [];
    await axios.get(`https://${serverIP}/api`, { withCredentials: true })
      .then((result) => {
          if(result.data == 'no data'){
              return
          }
          let count = 0;
          result.data.forEach(dt => { 
            tempRecipeList.push(new Recipe(count,dt.id,'',dt.title,'','',[],dt.images,dt.category,true,[],dt.time,dt.portion,'',dt.difficulty,'',dt.titlear,'','',[],[],dt.difficultyAr,'','',dt.premium))
            count++;
          }) 
      }).finally(()=>{
          setRecipeList(tempRecipeList)
      }).catch(async (error) => {
        if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
          const trial = await fetchCsrfToken(); // Refresh the token
          if(!trial){
            presentAlert({
              header: lang=='en'?"ُError!":"!خطأ",
              message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
              buttons: [lang=='en'?"Ok":"نعم"],
              cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
              mode: 'md',
            })  
          }else{
            // Retry the request after refreshing the token
            await getRecipes();
          }
        }else{
          presentAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message: lang=='en'?'Unexpected error Occured!':'حدث خطأ غير متوقع!',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })
        }
      });
  }
  //Get The Recent Recipes Only for Premium
  const getRecentRecipes = async () => {
    let tempRecentList = [];
    await axios.get(`https://${serverIP}/api/recent`, { withCredentials: true })
      .then((result) => {
          if(result.data == 'no data'){
              return
          }
          let count = 0;
          result.data.forEach(dt => {
            tempRecentList.push(new Recipe(count,dt.id,'',dt.title,'','',[],dt.images,dt.category,true,[],dt.time,dt.portion,'',dt.difficulty,'',dt.titlear,'','',[],[],dt.difficultyAr,''))
            count++;
          }) 
      }).finally(()=>{
          setRecentRecipes(tempRecentList)
      }).catch(async (error) => {
        if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
          const trial = await fetchCsrfToken(); // Refresh the token
          if(!trial){
            presentAlert({
              header: lang=='en'?"ُError!":"!خطأ",
              message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
              buttons: [lang=='en'?"Ok":"نعم"],
              cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
              mode: 'md',
            })  
          }else{
            // Retry the request after refreshing the token
            await getRecentRecipes();
          }
        }else{
          presentAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message: lang=='en'?'Unexpected error Occured!':'حدث خطأ غير متوقع!',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })
        }
      });
  }
  //Gets Favourite of current user if he is Logged in
  const getFavourite = async () => {
    let tempFavList= [];
    await axios.get(`https://${serverIP}/api/favourite`, { withCredentials: true })
      .then((result) => {
          if(result.data == 'no data'){
              return
          }
          let count = 0;
          result.data.forEach(dt => {
            tempFavList.push(new Recipe(count,dt.recipe_id,'',dt.recipe_title,'','',[],dt.recipe_images,dt.recipe_categories,true,[],dt.recipe_time,dt.recipe_portion,'',dt.difficulty,'',dt.titlear,'','',[],[],dt.difficultyAr,'','',dt.premium))
            count++;
          }) 
      }).finally(()=>{
          setFav(tempFavList)
      }).catch(async (error) => {
        if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
          const trial = await fetchCsrfToken(); // Refresh the token
          if(!trial){
            presentAlert({
              header: lang=='en'?"ُError!":"!خطأ",
              message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
              buttons: [lang=='en'?"Ok":"نعم"],
              cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
              mode: 'md',
            })  
          }else{
            // Retry the request after refreshing the token
            await getFavourite();
          }
        }else{
          presentAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message: lang=='en'?'Unexpected error Occured!':'حدث خطأ غير متوقع!',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })
        }
      });              
  }
  // Get Categories for the filtering
  const getCategories = async () => {
    let categoryList = [];
    await axios.get(`https://${serverIP}/api/categories`, { withCredentials: true })
      .then((result) => {
          if(result.data == 'no data'){
              return
          }
          result.data.forEach(dt => { 
              categoryList.push({id: dt.id, name: dt.category, nameAr: dt.nameAr});
          }) 
      }).finally(() => {
          setCategories(categoryList)
      }).catch(async (error) => {
        if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
          const trial = await fetchCsrfToken(); // Refresh the token
          if(!trial){
            presentAlert({
              header: lang=='en'?"ُError!":"!خطأ",
              message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
              buttons: [lang=='en'?"Ok":"نعم"],
              cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
              mode: 'md',
            })  
          }else{
            // Retry the request after refreshing the token
            await getCategories();
          }
        }else{
          presentAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message: lang=='en'?'Unexpected error Occured!':'حدث خطأ غير متوقع!',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })
        }
      })                          
  }
  //CSRF server Request
  const fetchCsrfToken = async () => {
    try {
      await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true}).then(()=>{
        return 1
      });
    } catch (error) {
      //Error Handling
      return 0
    }
  };
  //#endregion

  //#region Use Effect
  useEffect(() => {

    //Access Token
    const intervalId = setInterval(() => {
      // Call the refresh token endpoint to renew the access token
      axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
        .then(response => {
          //Response
        })
        .catch(error => {
          //Error Handling
        });
    }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
    
    //Access Token Refresh
    const authenticateAPI = async () => {  
      await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true})
      .then(response => {
        //Response
      })
      .catch(error => {
        //error handling
      });
    }

    //Check cookies for log in info
    const getProtectedResource = async () => {
      try {
        const response = await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true });
        setisLoggedin(true)
        setShowAccount(true)
        await axios.get(`https://${serverIP}/api/getUserInfo`, { withCredentials: true }).then((info)=>{
          if(info.data == 'no data'){
            return
          }
          setUserPremium(info.data.isPremium)
          setUsername(info.data.username)
          setUserEmail(info.data.email)
          setVerified(info.data.verified)
        });
        await getFavourite()
      } catch (error) {
        if (error.response && error.response.status === 401) { // Auth Cookie not found
          try {
            await axios.post(`https://${serverIP}/api/refresh`, {}, { withCredentials: true  });
            const retry = await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true });
            setisLoggedin(true)
            setShowAccount(true)
            await axios.get(`https://${serverIP}/api/getUserInfo`, { withCredentials: true }).then((info)=>{
              if(info.data == 'no data'){
                return
              }
              setUserPremium(info.data.isPremium)
              setUsername(info.data.username)
              setUserEmail(info.data.email)
              setVerified(info.data.verified)
            });
            getFavourite()
          } catch (refreshError) { // No refresh cookie found
            // console.log(refreshError)
            
          }
        }
      }
    };

    //Initialize
    const loadData = async () => {
      setLang(Cookies.get('language') || 'en')
      await fetchCsrfToken()
      await authenticateAPI()
      await getProtectedResource() //If logged in + favourite + subscribed?;
      await getRecentRecipes() //Recent premium recipes
      await getCategories() //Categories of the recipes
      await getRecipes()
      setLoading(false)
    }
    loadData()
    //Handle the resizing of the screen in real time
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    //removes listener when unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(intervalId); 
    };
  }, [location]);
  //#endregion

  return (
    <IonPage className="Page" >

      {/* Header */}
      <IonHeader className='home_header'>
          <NavBar username={username? username : ''} verified={verified} isSubscribed={isPremium} lang={lang} onLangClick={onLangClick} onLoginClick={onLoginClick} onSubscribeClick={onSubscribeClick} onVerify={verifyUser}  showAccount={showAccount} onLogout={onLogout} showLogin={!isLoggedin}></NavBar>
      </IonHeader>
      
      <div className='home_background'></div>
      

      {/* Content */}
      <IonContent  className='home_content' >

        {/* Background */}
        
        <div className='home_background_img'></div>


        {/* Swiper */}
        <Swiper
          className="home_slider"
          slides-per-view="1"
          grid-rows="1"
          mousewheel-force-to-axis="true"
          loop={true}
          navigation={true}
          module={[Navigation, Pagination]}
          pagination={{clickable: true}}
        >
          <SwiperSlide className='slide'>
            <img className='slideImg' src={one} alt='one'></img>
          </SwiperSlide>
          <SwiperSlide className='slide'> 
           <IonImg src={two} alt='two' className='slideImg'></IonImg>
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <IonImg src={three} alt='three' className='slideImg'></IonImg>
          </SwiperSlide>
          <SwiperSlide className='slide'>
            <IonImg src={four} alt='four' className='slideImg'></IonImg>
          </SwiperSlide>
        </Swiper>



        {/* Recent Recipes */}
        { !isLoading && recentRecipes.length>0 ? (
          <>
            <div className='recent_title'>
              <div className='recent_title_title' style={{}}>{t('Highlights')}</div>
            </div>

            <div className='recent'>
              <div className='recent_bg'></div>
              <div className='recent_container'>       
                <img src={crown}/>
                <img src={crown}/>
                <img src={crown}/>
                <img src={crown}/>
              </div>
              <div className='recent_recipes'> 
                <IonGrid className="lister_grid">
                  <IonRow className="lister_row" size="12">
                    {
                      recentRecipes.map((recipe,i)=>{return(
                        <IonCol className='lister_col' sizeXs="6" sizeSm="6" sizeMd='4' sizeLg='4' key={i}>
                          <PremiumReciCard recipe={recipe} onClickRecipe={onClickRecentRecipe} language={lang}/>
                        </IonCol> 
                      )})
                    }
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          </>
        ): null }   
       


       
        {/* Category Search and Favourite */}
        { !isLoading ? ( 
          <div className='categories_div'>
                <IonSelect interface="popover"  ref={categoryRef} className={selectedCategory==0 || selectedCategory==undefined ? 'home_category_select' : 'home_category_selected'} value={selectedCategory}  placeholder={t('Category')} onIonChange={e => {(setSelectedCategory(e.detail.value));filter(e.detail.value)}}>
                  <IonSelectOption className='home_category_item' value="" key={0}>{t('None')}</IonSelectOption>
                  {categories.map((category)=>{return(
                    <IonSelectOption className='home_category_item'  value={category.id} key={category.id}>{lang=="en"? 
                    category.name
                  : category.nameAr}</IonSelectOption>
                  )})}       
                </IonSelect>
                <IonSearchbar  value={searchData} onIonInput={setSearch} placeholder={t('Search')} showClearButton='focus'className={searchData=='' ? 'search' : 'searched'} ></IonSearchbar>
                { isLoggedin ? 
                <IonButton  className={favPressed ? 'home_fav_btn_clicked' : 'home_fav_btn'} onClick={setFavourite}>{t('Favourite')}</IonButton>
                :null
                }       
          </div>
        ): null }



        
        {windowWidth > 700 ? (


          // WINDOWS LANDSCAPE
          <div className='home_recipe_body'>

            {/* ADS */}
            <div className='home_ads'>
              <div className='ad_item'>&nbsp; </div>
              <div className='ad_item'>&nbsp; </div>
            </div>

            {/* Recipe List */}
            { !isLoading && !listLoading ? (
              
              // Recipe List If Favorite is clicked (includes search and category)
              favPressed ? (
                <IonGrid className="lister_grid">
                  <IonRow className="lister_row" size="4">
                    {(function() {
                      const filteredRecipes = allCategories 
                        ? favouriteList.filter(recipe => lang=='en' ?recipe.getTitle().toLowerCase().includes(searchData.toLowerCase()):recipe.getTitleAr().includes(searchData)) 
                        : favouriteList.filter(recipe => lang=='en' ? recipe.getCategory() === usedFilter && recipe.getTitle().toLowerCase().includes(searchData.toLowerCase()) : recipe.getCategory() === usedFilter && recipe.getTitleAr().includes(searchData));
                      return filteredRecipes.length > 0 ? (
                        filteredRecipes.map((recipe, i) => (
                        <IonCol className="lister_col" sizeXs="12" sizeSm="6" sizeMd='6' sizeLg='4' key={i}>
                          <RecipeCard recipe={recipe} onClickRecipe={onClickRecipe} language={lang}/>
                        </IonCol>
                      ))
                      ) : (
                        <div className='home_no_recipe'>{t('No Results')}</div>
                      );
                    })()
                    }
                  </IonRow>
                </IonGrid>
              ):


              // Recipe List Without Favorite (includes search and category)
              <IonGrid className="lister_grid">
                <IonRow className="lister_row" size="4">
                  {
                    (function() {
                      const filteredRecipes = allCategories 
                        ? recipeList.filter(recipe => lang=='en' ? recipe.getTitle().toLowerCase().includes(searchData.toLowerCase()):recipe.getTitleAr().includes(searchData)) 
                        : recipeList.filter(recipe => lang=='en' ? recipe.getCategory() === usedFilter && recipe.getTitle().toLowerCase().includes(searchData.toLowerCase()) : recipe.getCategory() === usedFilter && recipe.getTitleAr().includes(searchData));
                      return filteredRecipes.length > 0 ? (
                        filteredRecipes.map((recipe, i) => (
                          <IonCol className="lister_col" sizeXs="12" sizeSm="6" sizeMd='6' sizeLg='4' key={i}>
                            <RecipeCard recipe={recipe} onClickRecipe={onClickRecipe} language={lang}/>
                          </IonCol>
                        ))
                      ) : (
                        <div className='home_no_recipe'>{t('No Results')}</div>
                      );
                    })()
                  }
                </IonRow>
              </IonGrid>


            ) : (
            
              <div className='loading'>
                Loading
              <IonSpinner name='circular' className='spinner'>  </IonSpinner>
            </div>
            )
            }

            {/* ADS */}
            <div className='home_ads'>
              <div className='ad_item'>&nbsp; </div>
              <div className='ad_item'>&nbsp; </div>
            </div>

          </div>

        ):


        // WINDOWS PORTRAIT


        <div className='home_recipe_body'>

          {/* ADS */}
          {/* <div className='home_ads_mobile'>
            <div className='ad_item'>&nbsp; </div>
          </div> */}

          {/* Recipe List */}
          { !isLoading && !listLoading ? (
        
          // Recipe List If Favorite is clicked (includes search and category)
          favPressed ? (
            <IonGrid className="lister_grid">
              <IonRow className="lister_row" size="6">
                {(function() {
                  const filteredRecipes = allCategories 
                    ? favouriteList.filter(recipe => recipe.getName().toLowerCase().includes(searchData.toLowerCase())) 
                    : favouriteList.filter(recipe => recipe.getCategory() === usedFilter && recipe.getName().toLowerCase().includes(searchData.toLowerCase()));
                  return filteredRecipes.length > 0 ? (
                    filteredRecipes.map((recipe, i) => (
                    <IonCol className="lister_col" sizeXs="6" sizeSm="6" sizeMd='4' sizeLg='4' key={i}>
                      <RecipeCard recipe={recipe} onClickRecipe={onClickRecipe} language={lang}/>
                    </IonCol>
                  ))
                  ) : (
                    <div className='home_no_recipe'>{t('No Results')}</div>
                  );
                })()
                }
              </IonRow>
            </IonGrid>
          ):


          // Recipe List Without Favorite (includes search and category)
          <IonGrid className="lister_grid">
            <IonRow className="lister_row" size="6">
              {
                (function() {
                  const filteredRecipes = allCategories 
                    ? recipeList.filter(recipe => recipe.getName().toLowerCase().includes(searchData.toLowerCase())) 
                    : recipeList.filter(recipe => recipe.getCategory() === usedFilter && recipe.getName().toLowerCase().includes(searchData.toLowerCase()));
                  return filteredRecipes.length > 0 ? (
                    filteredRecipes.map((recipe, i) => (
                      <IonCol className="lister_col" sizeXs="6" sizeSm="6" sizeMd='4' sizeLg='4' key={i}>
                        <RecipeCard recipe={recipe} onClickRecipe={onClickRecipe} language={lang}/>
                      </IonCol>
                    ))
                  ) : (
                    <div className='home_no_recipe'>{t('No Results')}</div>
                  );
                })()
              }
            </IonRow>
          </IonGrid>


          ) : 

          <div className='loading'>
            <IonSpinner name='circular' className='spinner'></IonSpinner>
          </div>
          
          }



        </div>



}
            <Footer lang={lang}></Footer>
      </IonContent>
    </IonPage>
  );
};

export default Home;
