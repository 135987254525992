import React, {useRef, useState} from 'react';
import { IonInput, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonButton, IonTextarea} from '@ionic/react';
import axios from 'axios';
import "./Opinion.css"

//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';

function Opinion(props) {

    const serverIP = "recibits.com"
    const userRef = useRef("");
    const [user, setUser] = useState("")
    const emailRef = useRef("");
    const [email, setEmail] = useState("")
    const opRef = useRef("");
    const [op, setOp] = useState("")
    const [lang, setLang] = useState("en")
   //Language Data
   const resources = {
    en: {
        translation: {
          "Name": "Name",
          "Email": "Email",
          "Opinion": "Opinion",
          "Enter your email": "Enter Your Email",
          "Enter your full name": "Enter Your Name",
          "Enter your opinion": "Enter your opinion",
          "Submit": "Submit"
        },
    },
    ar: {
        translation: {
          "Name": "الاسم",
          "Email": "بريد إلكتروني",
          "Opinion": "رأي",
          "Enter your email": "أدخل بريدك الإلكتروني",
          "Enter your full name" : "أدخل اسمك",
          "Enter your opinion": "أدخل رأيك",
          "Submit": "أرسل"
  },
},
};

i18n.use(initReactI18next).init({
resources,
lng: localStorage.getItem('language') || props.lang, // Default language
keySeparator: false,
interpolation: { escapeValue: false },
});

    const handleSubmit = async (username, email, opinion) => {
        setOp("")
        setUser("")
        setEmail("")
        if(username !== ""){
            if(opinion !== ""){
                const result = {
                    email: email,
                    username: username,
                    opinion: opinion
                }
                await axios.post(`https://${serverIP}recipe/api/InsertOpinion`, result)  
                    .then(response => {
                        console.log(response);
                      })
                      .catch(error => {
                        console.log(error);
                      })
            }else{
                alert("Please Fill in the field with your Opinion")
            }
            
        }else{
            alert("Please Fill the username Field")
        }
        
    }
  
    return (

        <IonGrid className="form_container">
        <IonRow className="input_field">
          <IonCol size="12" className='opinion_title'> Share your opinion about the recipe!</IonCol>
            <IonCol size="12">
            {/* <IonItem className='opinion_item'> */}
              {/* <IonLabel position="stacked">{t('Name')}</IonLabel> */}
              <IonInput
                mode='md'
                style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}} 
                className='ion-invalid ion-touched opinion_input'
                value={user}
                ref={userRef}
                onIonChange={e => setUser(e.detail.value)}
                placeholder={t('Enter your full name')}
                type="text"
                label={t('Name')} 
                labelPlacement="floating" 
                fill="outline" 
              />
            {/* </IonItem> */}
            </IonCol>
          </IonRow>
          <IonRow className="input_field">  
            <IonCol size="12">
            {/* <IonItem> */}
              {/* <IonLabel position="stacked">{t('Email')}</IonLabel> */}
              <IonInput
                mode='md'
                style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}} 
                className='ion-invalid ion-touched opinion_input'
                value={email}
                ref={emailRef}
                onIonChange={e => setEmail(e.detail.value)}
                placeholder={t('Enter your email')}
                type="email"
                label={t('Email')} 
                labelPlacement="floating" 
                fill="outline"
              />
            {/* </IonItem> */}
            </IonCol>
           </IonRow>
           <IonRow className="textarea_field">
            <IonCol size="12"> 
            {/* <IonItem> */}
              {/* <IonLabel position="stacked">{t('Opinion')}</IonLabel> */}
              <IonTextarea
                mode='md'
                style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}} 
                className='ion-invalid ion-touched opinion_textfield'
                autoGrow={true}
                value={op}
                ref={opRef}
                onIonChange={e => setOp(e.detail.value)}
                placeholder={t('Enter your opinion')}    
                label={t('Opinion')} 
                labelPlacement="floating" 
                fill="outline"
              />
            {/* </IonItem> */}
            </IonCol>
          <IonCol size="12">
            <IonButton mode='md'  expand="block" onClick={() => handleSubmit(userRef.current.value, emailRef.current.value, opRef.current.value)} className="opinion_btn">{t('Submit')}</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

export default Opinion