//#region Imports
//Ionic Components
import { IonContent, IonHeader, IonInput, IonButton, IonPage, useIonAlert, IonSpinner } from "@ionic/react"
//Use React
import { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
//Components
import NavBar from "../components/NavBar";
//Axios
import axios from 'axios'
//Resources
import logo from '../resources/logo/logo_transparent_edited.png'
//JS Cookies
import Cookies from 'js-cookie';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
//Style
import './form.css'
//#endregion



const Login = () => {

  //#region Variables
  //Routes
  const history = useHistory();
  //Server IP Address
  const serverIP = "recibits.com"
  //References
  const userRef = useRef(null)
  const passRef = useRef(null)
  //Process checker
  const [loggingIn, setLoggingIn] = useState(false)
  //Alert
  const [loginAlert] = useIonAlert();
  //Language Data
  const [lang, setLang] = useState("en")
  const resources = {
    en: {
      translation: {
        "undefined": "",
        //Input Labels
        "Username": "Username",
        "Password": "Password",
        //Input Placeholders
        "EUsername": "Enter username",
        "EPass": "Enter password",
        //Login button
        "Login": "Login",
        //Links
        "CrAccount": "Create an Account",
        "FPass": "Forgot Password",
        //Errors
        "UsernameRequired": "Username required!",
        "PasswordRequired": "Password required!",
      },
    },
    ar: {
      translation: {
        "undefined": "",
        //Input Labels
        "Username": "الاسم",
        "Password": "كلمة المرور",
        //Input Placeholders
        "EUsername": "ادخل الاسم",
        "EPass": "ادخل كلمة المرور",
        //Login button
        "Login": "تسجيل دخول",
        //Links
        "CrAccount": "انشاء حساب",
        "FPass": "نسيت كلمة المرور",
        //Errors
        "UsernameRequired": "اسم المستخدم مطلوب!",
        "PasswordRequired": "كلمة المرور مطلوبة!"
      },
    },
  };
  i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || lang, // Default language
    keySeparator: false,
    interpolation: { escapeValue: false },
  });
  //Input Error
  const [usernameInputError, setUsernameInputError] = useState([])
  const [passwordInputError, setPasswordInputError] = useState([])
  const username_rules = [
    { test: (v) => !!v, message: 'UsernameRequired'},
  ]
  const password_rules = [
    { test: (v) => !!v, message: 'PasswordRequired'},
  ]
  //#endregion

  //#region Functions
  //Language Set From Cookies
  const onLangClick = () => {
    if(lang == "en"){
      setLang("ar")
      Cookies.set('language', "ar", { expires: 365, path: '/' });
    }
    else{
      setLang("en")
      Cookies.set('language', "en", { expires: 365, path: '/' });
    } 
  }
  //Validate Inputs
  const validateUsername = (username) => {
    const failedUsernameRules = username_rules.filter((rule) => !rule.test(username)).map((rule) => rule.message);
    setUsernameInputError(failedUsernameRules);
  }
  const validatePassword = (password) => {
    const failedPasswordRules = password_rules.filter((rule) => !rule.test(password)).map((rule) => rule.message);
    setPasswordInputError(failedPasswordRules);
  }
  //Perform Logging in procedure
  const login = async (username, password) => {
    //Checking Input Validity
    if(username==''){
      loginAlert({
        header: lang=='en'?"Alert":"تنبيه",
        message: lang=='en'?'Username is required to Login!':'مطلوب اسم المستخدم لتسجيل الدخول!',
        buttons: [lang=='en'?"Ok":"نعم"],
        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
        mode: 'md',
      })
      validateUsername('')
      return
    }
    if(password==''){
      loginAlert({
        header: lang=='en'?"Alert":"تنبيه",
        message: lang=='en'?'Password is required to Login!':'كلمة المرور مطلوبة لتسجيل الدخول!',
        buttons: [lang=='en'?"Ok":"نعم"],
        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
        mode: 'md',
      })
      validatePassword('')
      return
    }
    //Start Log in process
    setLoggingIn(true)
    try{
      await axios.post(`https://${serverIP}/api/login`, {username, password}, { withCredentials: true })
        .then(async (result) => {
          if(result.data.length == 0){
              loginAlert({
                  header: lang=='en'?"Alert":"تنبيه",
                  message:  lang=='en'?'Incorrect Credentials':'البيانات غير صحيحة',
                  buttons: [lang=='en'?"Ok":"نعم"],
                  cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                  mode: 'md',
                })
                return
          }
          else{               
              history.replace({
                  pathname: '/Home'
              })
          }       
        })
    }catch(error){
      //If csrf error occured
      if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
        const trial = await fetchCsrfToken(); // Refresh the token
        if(!trial){
          loginAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })  
        }else{
          // Retry the request after refreshing the token
          login(username,password);
        }
      }else{
        loginAlert({
          header: lang=='en'?"ُError!":"!خطأ",
          message: lang=='en'?'Unexpected error Occured!':'حدث خطأ غير متوقع!',
          buttons: [lang=='en'?"Ok":"نعم"],
          cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
          mode: 'md',
        })  
      }   
    }
    setLoggingIn(false)  
  }
  //CSRF server Request
  const fetchCsrfToken = async () => {
    try {
      await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true}).then(()=>{
        return 1
      });
    } catch (error) {
      //Error Handling
      return 0
    }
  };
  //#endregion

  //#region Use Effect
  useEffect(()=>{

    // Access Token
    const intervalId = setInterval(() => {
        // Call the refresh token endpoint to renew the access token
        axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
          .then(response => {
            //response
          })
          .catch(error => {
            //error handling
          });
    }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
      
    // Access Token Refresh
    const authenticateAPI = async () => {  
      await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
      .then(response => {
        //response
      })
      .catch(error => {
        //error handling
      });
    }

    //Check cookies for log in info
    const getProtectedResource = async () => {
      try {
        await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true }).then(()=>{
          history.replace({pathname: '/home'})
        });
      } catch (error) {
        if (error.response && error.response.status === 401) { // Auth Cookie not found
          try {
            await axios.post(`https://${serverIP}/api/refresh`, {}, { withCredentials: true  });
            await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true }).then(()=>{
              history.replace({pathname: '/home'})
            }).catch((error)=>{/*Error handling*/});
          } catch (refreshError) { // No refresh cookie found
            //error handling
          }
        }
      }
    };

    //Initialize
    const loadData = async () => {
        setLang(Cookies.get('language') || 'en')
        await fetchCsrfToken()
        await authenticateAPI()
        await getProtectedResource()
    }
    
    loadData()

    return () => {
        clearInterval(intervalId); 
    };

  },[])
  //#endregion

  return(
    <IonPage >
      <IonHeader className='form_header'>
          <NavBar lang={lang} onLangClick={onLangClick}></NavBar>
      </IonHeader>
      <IonContent>
      <div className='form_background'></div>
      <div className='form_background_img'></div>
      <div className="form_main">
        <div className="form">
          <img src={logo} className="form_logo"></img>
          <IonInput 
            mode="md"   
            style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}} 
            ref={userRef} 
            className="ion-invalid ion-touched form_input" 
            label={t('Username')} 
            labelPlacement="floating" 
            fill="outline"
            placeholder={t('EUsername')}
            helperText="Enter Your Username"
            errorText= {t(`${usernameInputError[0]}`)}
            onIonInput={(e)=>validateUsername(e.detail.value || '')}
          >
          </IonInput>
          <IonInput 
            mode="md"  
            style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}} 
            ref={passRef} 
            className="ion-invalid ion-touched form_input" 
            label={t('Password')} 
            type="password" 
            labelPlacement="floating" 
            fill="outline" 
            placeholder={t('EPass')}
            helperText="Enter Your Password"
            errorText= {t(`${passwordInputError[0]}`)}
            onIonInput={(e)=>validatePassword(e.detail.value || '')}
          >
          </IonInput>
          {!loggingIn ?
            <IonButton aria-hidden="false" mode="md" className="form_btn" onClick={() => login(userRef.current.value, passRef.current.value)}>{t('Login')}</IonButton>
            :
            <IonButton mode="md" className="form_btn"><IonSpinner name='circular' className='form_spinner'/></IonButton>
          }
          <div className="form_tags">
            <div className="form_tag_left"><a className="form_tag"   href="/signup">{t('CrAccount')}</a></div>
            <div className="form_tag_right"><a className="form_tag"  href='/forgetPass'>{t('FPass')}</a></div>
          </div>
        </div>
      </div>
      </IonContent>
    </IonPage>
  )
    
  }



export default Login;