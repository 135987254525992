//#region Imports
//Ionic Components
import { IonContent, IonHeader, IonInput, IonButton, IonPage, useIonAlert, IonSpinner } from "@ionic/react"
//Use React
import { useRef, useState, useEffect} from 'react';
import { useHistory } from 'react-router';
//Components
import NavBar from "../components/NavBar";
//Axios
import axios from 'axios'
//Resources
import logo from '../resources/logo/logo_transparent_edited.png'
//JS Cookies
import Cookies from 'js-cookie';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
//Style
import './form.css'
//#endregion


const ForgetPass = () => {

    //#region Variables
    //Routes
    const history = useHistory();
    //Server IP Address
    const serverIP = "recibits.com"
    //References
    const emailRef = useRef(null);
    //Input Error
    const [inputError, setInputError] = useState([])
    const rules = [
        { test: (v) => !!v, message: 'EmailRequired' }, // Ensure the input is not empty
        { 
            test: (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v), 
            message: 'EmailIncorrect'
        },
    ];
    //Process checker
    const [processing, setProcessing] = useState(false)
    //Alert
    const [forgetPassAlert] = useIonAlert();
    //Language Data
    const [lang, setLang] = useState("en") 
    const resources = {
        en: {
            translation: {
                "undefined": "",
                //Input Labels
                "Email": "Email",
                //Input Placeholders
                "Enter Your Email": "Enter Your Email",
                //Label
                "Enter": "Enter the email that is associated with your account",
                //Submit button
                "Submit": "Submit",
                //Errors
                "EmailRequired": "Email is required.",
                "EmailIncorrect": "Must be a valid email address.",
            },
        },
        ar: {
            translation: {
                "undefined": "",
                //Input Labels
                "Email": "بريد إلكتروني", 
                //Input Placeholders
                "Enter Your Email": "أدخل بريدك الإلكتروني",
                //Label
                "Enter": "أدخل البريد الإلكتروني المرتبط بحسابك",
                //Submit button
                "Submit": "قدم",
                //Errors
                "EmailRequired": "البريد الإلكتروني مطلوب.",
                "EmailIncorrect": "يجب أن يكون عنوان بريد إلكتروني صالحًا.",
            },
        },
    };
    i18n.use(initReactI18next).init({
        resources,
        lng: localStorage.getItem('language') || lang, // Default language
        keySeparator: false,
        interpolation: { escapeValue: false },
    });
    //#endregion

    //#region Functions
    //Language Set From Cookies
    const onLangClick = () => {
        if(lang == "en"){
            setLang("ar")
            Cookies.set('language', "ar", { expires: 365, path: '/' });
        }
        else{
            setLang("en")
            Cookies.set('language', "en", { expires: 365, path: '/' });
        } 
    }
    //Validate Inputs
    const validateEmail = (input) => {
        const failedRules = rules.filter((rule) => !rule.test(input)).map((rule) => rule.message);
        setInputError(failedRules);
    }
    //Sending Email Process
    const sendEmail = async (email) => {
        if(email==''){
            forgetPassAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?'Please Enter an Email to proceed!':'الرجاء إدخال البريد الإلكتروني للمتابعة!',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
            validateEmail('')
            return
        }
        if(inputError.length>0){
            forgetPassAlert({
                header: lang=='en'?"Alert":"تنبيه",
                message: lang=='en'?'The Email you typed is not acceptable!':'البريد الإلكتروني الذي كتبته غير مقبول!',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
            })
            return
        }
        setProcessing(true)
        try{
            await axios.post(`https://${serverIP}/api/verify`,{email},{withCredentials:true})
                .then(async (result) => {
                    forgetPassAlert({
                        header: lang=='en'?'Done!':"!انتهى",
                        message:  lang=='en'?"If the email exists, a password reset email has been sent.":'إذا كان البريد الإلكتروني موجودًا، فقد تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور.',
                        buttons: [lang=='en'?"Ok":"نعم"],
                        cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                        mode: 'md',
                    })
                    history.replace('/')
            })
        }catch(error){
            if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
                const trial = await fetchCsrfToken(); // Refresh the token
                if(!trial){
                forgetPassAlert({
                    header: lang=='en'?"ُError!":"!خطأ",
                    message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
                    buttons: [lang=='en'?"Ok":"نعم"],
                    cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                    mode: 'md',
                })  
                }else{
                    // Retry Send Email
                    sendEmail(email);
                }
            }else{
                forgetPassAlert({
                    header: lang=='en'?"ُError!":"!خطأ",
                    message: lang=='en'?'An error occured! Please try again.':'حدث خطأ! يرجى المحاولة مرة أخرى.',
                    buttons: [lang=='en'?"Ok":"نعم"],
                    cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                    mode: 'md',
                })
            }
        }
        setProcessing(false)
    }
    //CSRF server Request
    const fetchCsrfToken = async () => {
        try {
        await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true}).then(()=>{
            return 1
        });
        } catch (error) {
        //Error Handling
        return 0
        }
    };
    //#endregion

    //#region Use Effect
    useEffect(()=> {

        //Access Token
        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
                .then(response => {
                    //Response
                })
                .catch(error => {
                    //Error Handling
                });
        }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
            
        //Access Token Refresh
        const authenticateAPI = async () => {  
            await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
                .then(response => {
                    //Response
                })
                .catch(error => {
                    //Error Handling
            });
        }

        const loadData = async () => {
            setLang(Cookies.get('language') || 'en')
            await fetchCsrfToken()
            await authenticateAPI()
        }
        
        loadData()
    
        return () => {
            clearInterval(intervalId); 
        };
    },[]) 
    //#endregion   

    return(
        <IonPage >
            <IonHeader className='form_header'>
                <NavBar lang={lang} onLangClick={onLangClick}></NavBar>
            </IonHeader>
            <IonContent className="form_content">
                <div className='form_background'></div>
                <div className='form_background_img'></div>
                <div className="form_main">
                    <div className="form">
                        <img src={logo} className="form_logo"></img>
                        <div className="form_label">{t('Enter')}</div>
                        <IonInput 
                            style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}} 
                            ref={emailRef} 
                            className="ion-invalid ion-touched form_input" 
                            label={t('Email')} 
                            type="email" 
                            labelPlacement="floating" 
                            fill="outline" 
                            placeholder={t('Enter Your Email')}
                            helperText="Enter Your New Password"
                            errorText={t(`${inputError[0]}`)}
                            onIonInput={(e)=>validateEmail(e.detail.value || '')}
                        >
                        </IonInput>
                        {!processing ?
                            <IonButton mode="md" className="form_btn" onClick={() => sendEmail(emailRef.current.value)}>{t('Submit')}</IonButton>
                            :
                            <IonButton mode="md" className="form_btn"><IonSpinner name='circular' className='form_spinner'/></IonButton>
                        }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
    }



export default ForgetPass;