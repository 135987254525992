//#region Imports
//Import Ionic Components
import { IonButton, IonContent, IonHeader, IonSegment, IonPage, IonLabel, IonSegmentButton } from "@ionic/react"
//Import Components
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
import PaymentForm from '../components/PaymentForm';
//Import React Components
import { useHistory, useLocation } from 'react-router';
import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
//Import Axios
import axios from "axios";
//Import Style
import './Premium.css';
import SubscriptionButton from "../components/SubscribeButton";
//#endregion

//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';

// import bg from '../resources/marble.png'

const Premium = () => {

    //Language Data
    const [lang, setLang] = useState("en")
    const resources = {
    en: {
        translation: {
            "Buy Our Premium Plans": "Buy Our Premium Plans",
            "Monthly": "Monthly",
            "Yearly": "Yearly",
            "• Ability to save your favorite Recipe": "• Ability to save your favorite Recipe",
            "• Access to Premium Recipes": "• Access to Premium Recipes",
            "Basic Plan": "Basic Plan",
            "Subscribe": "Subscribe",
            "Premium Plan": "Premium Plan",
            "• Coming soon....": "• Coming soon....",

        },
    },
    ar: {
        translation: {
            "Buy Our Premium Plans": "اشترِ خططنا المميزة",
            "Monthly": "شهريا",
            "Yearly": "سنوي",
            "• Ability to save your favorite Recipe": "• القدرة على حفظ الوصفة المفضلة لديك",
            "• Access to Premium Recipes": "• الوصول إلى وصفات مميزة",
            "Basic Plan": "الخطة الأساسية",
            "Subscribe": "اشترك",
            "Premium Plan": "الخطة المميزة",
            "• Coming soon....": "• قريباً....",
  },
},
};

i18n.use(initReactI18next).init({
resources,
lng: localStorage.getItem('language') || lang, // Default language
keySeparator: false,
interpolation: { escapeValue: false },
});

const onLangClick = () => {
    if(lang == "en"){
      setLang("ar")
    }
    else{
      setLang("en")
    } 
  }

    //#region Variables
    const [view, setView] = useState('monthly');
    const location = useLocation();
    const receivedData = location.state && location.state.data;
    const history = useHistory();
    const [personId, setPersonId] = useState(null)
    const [isLoggedin, setisLoggedin] = useState(false)
    const [showAccount, setShowAccount] = useState(false)
    const [isPremium, setUserPremium] = useState(false)
    const [premiumType, setPremiumType] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const serverIP = "localhost"
    const [error, setError] = useState(null)
    // Load your publishable key
    const stripePromise = loadStripe("pk_test_51QFdhRQJNg0lHSmdiuooH70TIDb56FACk2HNRM7SixqXgF8MDW6fycIvRMSSkYmfkHNUkasFzjyhWZ1isVmjHQvO00ahX8kLKf");
    const options = {
        // passing the client secret obtained from the server
        // clientSecret: '{{CLIENT_SECRET}}',
    };
    //#endregion

    //#region Functions
    const handleSegmentChange = (event) => {
        setView(event.detail.value);
    };

    const subscribeUser = () => {
        if (!receivedData.isLoggedin){
            history.replace({
                pathname: '/login',
              })
        } else{
            alert("done")
        }
    }  
    //#endregion

    //#region Use Effect
    useEffect(()=>{

        
        const fetchCsrfToken = async () => {
            try {
                await axios.get(`http://${serverIP}:3002/api/csrf-token`,{withCredentials: true});
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        const intervalId = setInterval(() => {
            // Call the refresh token endpoint to renew the access token
            axios.post(`http://${serverIP}:3002/api/refresh-authenticate`,{},{withCredentials: true })
                .then(response => {
                // console.log('Access token refreshed');
                })
                .catch(error => {
                // console.error('Error refreshing token:', error);
            });
        }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
            
        
        const authenticateAPI = async () => {  
            await axios.post(`http://${serverIP}:3002/api/authenticate`,{}, { withCredentials: true })
                .then(response => {
                    console.log('Tokens set');
                })
                .catch(error => {
                    console.error('Error setting tokens:', error);
            });
        }

        //Check cookies for log in info and save the id
        const getProtectedResource = async () => {
            try {
            const response = await axios.get(`http://${serverIP}:3002/api/protected`, { withCredentials: true });
            setisLoggedin(true)
            setShowAccount(true)
            setPersonId(response.data)
            checkPremium(response.data)
            } catch (error) {
            if (error.response && error.response.status === 401) { // Auth Cookie not found
                try {
                await axios.post(`http://${serverIP}:3002/api/refresh`, {}, { withCredentials: true });
                const retry = await axios.get(`http://${serverIP}:3002/api/protected`,{ withCredentials: true });
                setisLoggedin(true)
                setShowAccount(true)
                setPersonId(retry.data)
                checkPremium(retry.data)
                } catch (refreshError) { // No refresh cookie found
                console.log(refreshError)
                return
                }
            }
            }
        };

            //Check if the logged in user is subscribed
        const checkPremium = async (id) => {
            await axios.get(`http://${serverIP}:3002/api/checkPremium?id=${encodeURIComponent(id)}`)
                    .then((result) => {
                        if(result.data == 'no data'){
                            return
                        }
                        if(result.data)
                            setUserPremium(true)
                    }).catch((err) => {
                        setError(err)
                    });
        }
  

        
      
        const loadData = async () => {
            await fetchCsrfToken()
            await authenticateAPI()
            await getProtectedResource()
        }
        
        loadData()
    
        return () => {
            clearInterval(intervalId); 
        };

  
      },[])
    //#endregion

return(
    <IonPage>
      <IonHeader class='premium_header'>
        
          
          <NavBar lang={lang} onLangClick={onLangClick}></NavBar>
      </IonHeader>
      <IonContent fullscreen>
        <div className='premium_background'></div>
        <div className='premium_background_img'></div>

        { isProcessing ? (
         <Elements stripe={stripePromise} options={options}>
         <div className="paymentForm">
             <PaymentForm />
         </div>
         </Elements>
        ):(
        <div className="premium_">
            <h2 className="mainTitle">{t('Buy Our Premium Plans')}</h2> <br></br> <br></br>
            <IonSegment  color="tertiary" value={view} onIonChange={handleSegmentChange} className="segments">
                <IonSegmentButton className="segment_btn"   value="monthly">
                <IonLabel  className="segment_label">{t('Monthly')}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton className="segment_btn" value="yearly">
                <IonLabel className="segment_label">{t('Yearly')}</IonLabel>
                </IonSegmentButton>
            </IonSegment>
            <div className="body-div">

            
                
                <div className="plan_div">
                
                    <div className="plan_name_div"><div className="flap_background"></div><div className="plan_name">{t('Basic Plan')}</div> <div className="plan_closed">•</div></div>

                    <div className="plan_items" >
                        <div className="plan_item" style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}>{t('• Ability to save your favorite Recipe')}</div>
                        
                        <div className="plan_item" style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}>{t('• Access to Premium Recipes')}</div>
                        
                        {/* <div className="plan_item">Enjoy surfing our website with No Ads!</div> */}
                    </div>

                    <div className="plan_price"><span className="price_value">{view == "monthly" ? '$5' : '$50'}</span> {view == "monthly" ? '/month' : '/year'}</div>     
                    <IonButton className="plan_subscribe" color="secondary" onClick={subscribeUser}> {t('Subscribe')} </IonButton>

                </div>
                <div className="plan_div">
                
                    <div className="plan_name_div"><div className="flap_background"></div><div className="plan_name">{t('Premium Plan')}</div> <div className="plan_closed">•</div></div>

                    <div className="plan_items">
                        <div className="plan_item" style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}>{t('• Coming soon....')}</div>
                        
                        {/* <div className="plan_item">Get Premium Recipes the second they get published!</div> */}
                        
                        {/* <div className="plan_item">Enjoy surfing our website with No Ads!</div> */}
                    </div>
                    
                    <div className="plan_price"><span className="price_value">{view == "monthly" ? '$12' : '$130'}</span>{view == "monthly" ? '/month' : '/year'}</div>     
                    <IonButton className="plan_subscribe" color="secondary" onClick={subscribeUser}> {t('Subscribe')} </IonButton>

                </div>
                {/* <div className="plan_div">
                
                    <div className="plan_name_div"><div className="flap_background"></div><div className="plan_name">Ultimate Plan</div> <div className="plan_closed">•</div></div>

                    <div className="plan_items">
                        <div className="plan_item">• Coming soon....</div>

                    </div>
                    
                    <div className="plan_price"><span className="price_value">{view == "monthly" ? '$17' : '$180'}</span>{view == "monthly" ? '/month' : '/year'}</div>     
                    <IonButton color="secondary"> Subscribe </IonButton>

                </div> */}
            </div>
            {/* <div className="body-div">

            </div> */}

            



        </div>
        )}

        
            
            <Footer lang={lang}></Footer>

            </IonContent>
   
      </IonPage>
)

}

export default Premium;