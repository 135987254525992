import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';
//Import Axios
import axios from "axios";


const stripePromise = loadStripe('YOUR_PUBLISHABLE_KEY');

const serverIP = "localhost"


const SubscriptionButton = ({ plan, currency }) => {
  const [clientSecret, setClientSecret] = useState('');

  const handleSubscribe = () => {
    // Create a payment intent on the server
    axios.post(`http://${serverIP}:3002/api/create-payment-intent`, {
      amount: plan.amount,
      currency,
    })
      .then((response) => {
        setClientSecret(response.data.clientSecret);
      })
      .catch((error) => {
        console.error('Error creating payment intent:', error);
      });
  };

  return (
    <>
      <button onClick={handleSubscribe}>Subscribe</button>
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentForm />
        </Elements>
      )}
    </>
  );
};

export default SubscriptionButton;
