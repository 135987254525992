import { IonButton, IonButtons, IonIcon, IonImg, IonItem, IonList, IonSelect, IonSelectOption, IonTab, IonToolbar } from "@ionic/react";
import './Footer.css';
import logo from '../resources/logo/logo_transparent_edited.png';
import { useHistory} from 'react-router';
import { Link, NavLink } from 'react-router-dom';   

//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';

const Footer = (props) => {
  //Language Data
  const resources = {
    en: {
        translation: {
          "Explore": "Explore for a variety of recipes, from easy to challenging, catering to all skill levels. Each recipe is carefully detailed to guide you through every step. Whether you're a novice or a pro, I've got the perfect dish for you. Let's cook up something delicious!",
          "Categories": "Categories",
          "Sweets": "Sweets",
          "Drinks": "Drinks",
          "Salads": "Salads",
          "Sauces": "Sauces",
          "And a lot More": "And a lot More",
          "About Us": "About Us",
          "Contact Us": "Contact Us",
          "Privacy Policy": "Privacy Policy",
          "Terms and Conditions": "Terms and Conditions",
        },
    },
    ar: {
        translation: {
          "Explore": "استكشف مجموعة متنوعة من الوصفات، بدءًا من الوصفات السهلة وحتى الصعبة، والتي تلبي جميع مستويات المهارة. تم تفصيل كل وصفة بعناية لإرشادك خلال كل خطوة. سواء كنت مبتدئًا أو محترفًا، فلدينا الطبق المثالي لك. دعونا طهي شيء لذيذ!",
          "Categories": "فئات",
          "Sweets": "حلويات",
          "Drinks": "مشروبات",
          "Salads": "السلطات",
          "Sauces": "صلصات",
          "And a lot More": "وأكثر من ذلك بكثير",
          "About Us": "معلومات عنا",
          "Contact Us": "اتصل بنا",
          "Privacy Policy": "سياسة الخصوصية",
          "Terms and Conditions": "الشروط والأحكام",
        },
    },
  };


  const serverIP = "localhost"

  i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || props.lang, // Default language
    keySeparator: false,
    interpolation: { escapeValue: false },
  });
  
  const history = useHistory();
  const switchPrivacy = () => {
    history.push({
      pathname: '/home/Privacy'
    })
  }

  const switchTerms = () => {
    history.push({
      pathname: '/home/Terms'
    })
  }

  const handleCategoryChoice = (category) => {

  }

   return(
    <div className="footer">
      <div className="column3">
        <img src={logo} className="logo"></img>
      </div>
      <div/>
      <div className="column1">
        <p className="par">
          {t('Explore')}
        </p>
      </div>
      <div/>
      <div className="column">
        <h5 className="title">{t('Categories')}</h5>
        <ul>
          <li><Link to={{pathname:"/",state:"Sweets"}} >{t('Sweets')}</Link></li>
          <li><Link to="/home" state={{ category: "Drinks" }}>{t('Drinks')}</Link></li>
          <li><Link to="/home" state={{ category: "Salads" }}>{t('Salads')}</Link></li>
          <li><Link to="/home" state={{ category: "Sauces" }}>{t('Sauces')}</Link></li>
          <li><p className="listpar">{t('And a lot More')}</p></li>
        </ul>
      </div>
      <div/>
      <div className="column">
        <h5 className="title">{t('About Us')}</h5>
        <ul>
          <li><a href={`/home/aboutus`}>{t('About Us')}</a></li>
          <li><a href={`/home/contact`}>{t('Contact Us')}</a></li>
          <li><a href={`/home/Privacy`} className="link" >{t('Privacy Policy')}</a></li>
          <li><a href={`/home/Terms`} className="link" >{t('Terms and Conditions')}</a></li>
        </ul>
      </div>
      <div/>
      <div className="column">
        <h5 className="title">{t('Contact Us')}</h5>
        <ul>
          <li><a href="https://www.instagram.com/recibits_?utm_source=qr&igsh=dTNzYmU1MnlqMmFu">Instagram</a></li>
          {/* <li><a href="#">Facebook</a></li> */}
          {/* <li><a href="https://twitter.com/ReciBits_">Twitter</a></li> */}
          <li><a href="https://www.facebook.com/share/p/15LnT4feL9/">Facebook</a></li>
          {/* <li><a href="#">Facebook</a></li> */}
        </ul>
      </div>

      <div className="owner">
        <h5 className="title">Owned by CEDARIO LTD</h5>
      </div>
    </div>
   )
    

}

export default Footer;