//#region 
//Import Ionic Components
import { IonContent, IonHeader, IonPage } from "@ionic/react"
//Import Components
import NavBar from "../components/NavBar";
import Footer from '../components/Footer';
//Import React Components
import {useState, useEffect} from 'react';
//Import Style
import './Contact.css';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
//Import JS Cookies
import Cookies from 'js-cookie';
//#endregion

const Contact = () => {

  //#region Variables
  //Language Data
  const [lang, setLang] = useState("en")
  const resources = {
      en: {
          translation: {
              "Contact Details": "Contact Details",
              "Please": "Please dont hesitate to contact us through the folowing email:"
          },
      },
      ar: {
          translation: {
              "Contact Details": "للاتصال بنا",
              "Please": "لا تتردد في التواصل معنا عبر البريد الإلكتروني التالي"
    },
  },
  };
  i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || lang, // Default language
  keySeparator: false,
  interpolation: { escapeValue: false },
  });
  const email = 'contact@recibits.co'
  //#endregion

  //#region Functions
  const onLangClick = () => {
    if(lang == "en"){
      setLang("ar")
      Cookies.set('language', "ar", { expires: 365, path: '/' });
    }
    else{
      setLang("en")
      Cookies.set('language', "en", { expires: 365, path: '/' });
    } 
  }
  const handleClick = (event) => {
    event.preventDefault();
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`);
  };
  //#endregion

  //#region Use Effect
  useEffect(()=>{
    setLang(Cookies.get('language') || 'en')
  },[])
  //#endregion

return(
    <IonPage>
      <IonHeader class='contactus_header'>
          <NavBar lang={lang} onLangClick={onLangClick}></NavBar>
      </IonHeader>
      <div className='contactus_background'></div>
      <IonContent className="contactus_content">
        <div className='contactus_background_img'></div>
        <div className="contactus_story" style={lang=='en'?{direction: "ltr", textAlign: "left",marginLeft:"10px"}:{direction: "rtl", textAlign: "right",marginRight:"10px"}}>
            <h2 className="contactus_title">{t('Contact Details')}</h2> 
            <br></br> <br></br>
            <h4 className="contactus_title_2">{t('Please')}</h4>
            <br></br>
            <div onClick={handleClick} className="contact_email">contact@recibits.co</div><br></br>
        </div>
        <Footer lang={lang}></Footer>
      </IonContent>
    </IonPage>
    )
}

export default Contact;