import { Component } from "react";


class Recipe extends Component{

    constructor(Id, rid, name, title, subTitle, description, steps, images, category, isVisible, ingredients, time, portion, origin, difficulty, nameAr, titleAr, subTitleAr, descriptonAr, stepsAr,ingredientsAr, difficultyAr, originAr, categoryAr,isPremium) {

        super()
      
        this.state = {
             id: Id,
             rid: rid,
             name: name,
             title: title,
             subTitle: subTitle,
             description: description,
             steps: steps,
             images: images,
             category: category,
             isVisible: isVisible,
             ingredients: ingredients,
             time: time,
             portion: portion,
             origin: origin,
             difficulty: difficulty,
             nameAr: nameAr,
             titleAr: titleAr,
             subTitleAr: subTitleAr,
             descriptonAr, descriptonAr,
             stepsAr: stepsAr,
             ingredientsAr: ingredientsAr,
             difficultyAr: difficultyAr,
             originAr: originAr,
             categoryAr: categoryAr,
             isPremium: isPremium,
        }
    }

    //#region Getters
    getId(){
        return this.state.id;
    }
    getrId(){
        return this.state.rid;
    }
    getName(){
        return this.state.name;
    }
    getTitle(){
        return this.state.title;
    }
    getSubTitle(){
        return this.state.subTitle;
    }
    getDescription(){
        return this.state.description;
    }
    getSteps(){
        return this.state.steps;
    }
    getImages(){
        return this.state.images;
    }
    getCategory(){
        return this.state.category;
    }
    getIsVisible(){
        return this.state.isVisible;
    }
    getIngredients(){
        return this.state.ingredients;
    }
    getTime(){
        return this.state.time;
    }
    getPortion(){
        return this.state.portion;
    }
    getOrigin(){
        return this.state.origin;
    }
    getDifficulty(){
        return this.state.difficulty;
    }
    getNameAr(){
        return this.state.nameAr;
    }
    getTitleAr(){
        return this.state.titleAr;
    }
    getSubTitleAr(){
        return this.state.subTitleAr;
    }
    getDescriptionAr(){
        return this.state.descriptonAr;
    }
    getStepsAr(){
        return this.state.stepsAr;
    }
    getIngredientsAr(){
        return this.state.ingredientsAr;
    }
    getDifficultyAr(){
        return this.state.difficultyAr;
    }
    getOriginAr(){
        return this.state.originAr;
    }
    getCategoryAr(){
        return this.state.categoryAr;
    }
    getIsPremium(){
        return this.state.isPremium;
    }
    //#endregion




    render() {
        return(
            <p>{this.state.name}</p>
        )
    }

}

export default Recipe