import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Pages */
import Home from './pages/Home';
import RecipePage from './pages/RecipePage';
import AboutUs from './pages/AboutUs';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Verify from './pages/Verify';
import ForgetPass from './pages/ForgetPass';
import ChangePass from './pages/ChangePass';
import ResetPass from './pages/ResetPass';
import Contact from './pages/Contact';
import Premium from './pages/Premium'
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

setupIonicReact({
  scrollAssist: false,
  scrollPadding: false
});

const App = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/recipe/:id/:name">
          <RecipePage/>
        </Route>
        <Route exact path="/home/aboutus">
          <AboutUs/>
        </Route>
        <Route exact path="/home/contact">
          <Contact/>
        </Route>
        <Route exact path="/premium">
          <Premium/>
        </Route>
        <Route exact path="/login">
          <Login/>
        </Route>
        <Route exact path="/signup">
          <SignUp/>
        </Route>
        <Route exact path="/verify/:token">
          <Verify/>
        </Route>
        <Route path="/forgetpass">
          <ForgetPass/>
        </Route>
        <Route exact path="/changepass/:token">
          <ChangePass/>
        </Route>
        <Route exact path="/resetPass">
          <ResetPass/>
        </Route>
        <Route exact path="/home/privacy">
          <Privacy/>
        </Route>
        <Route exact path="/home/terms">
          <Terms/>
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);


export default App;
