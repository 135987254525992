//#region Imports
//Ionic Components
import {  IonContent, IonHeader, IonPage, IonIcon, useIonAlert } from '@ionic/react';
import { peopleOutline, speedometerOutline, timeOutline } from 'ionicons/icons';
//Use React
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState} from 'react';
//Components
import NavBar from '../components/NavBar';
import Opinion from '../components/Opinion';
// Classes
import Recipe from '../components/Recipe';
// Lightbox
import Lightbox from "yet-another-react-lightbox";
import {Counter, Fullscreen, Zoom, Thumbnails} from "yet-another-react-lightbox/plugins";
// Axios
import axios from 'axios'
// Resources
import starOff from '../resources/StarOff.png'
import starOn from '../resources/StarOn.png'
import Footer from '../components/Footer';
//JS Cookies
import Cookies from 'js-cookie';
//MultiLanguage
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { t } from 'i18next';
// Style
import './RecipePage.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
//#endregion


const RecipePage = () => {

    
   //Language Data
  





  //#region Variables
  //Routes
  const history = useHistory();
  const { id, name } = useParams();
  //Server IP Address
  const serverIP = "recibits.com"
  //Process checker
  const [ready, setIsReady] = useState(false)
  const [isLoading, setisLoading] = useState(true)
  //Alert
  const [recipeAlert] = useIonAlert();
  //Language Data
  const [lang, setLang] = useState("en")
  const resources = {
    en: {
        translation: {
          "Time": "Time",
          "Portion": "Portion",
          "Difficulty": "Difficulty",
          "Subclass:": "Subclass:",
          "Category:": "Category:",
          "Country:": "Country:",
          "Ingredients": "Ingredients",
          "Steps": "Steps",

        },
    },
    ar: {
        translation: {
          "Time": "وقت",
          "Portion": "كمية",
          "Difficulty": "صعوبة",
          "Subclass:": "فئة فرعية:",
          "Category:": "فئة:",
          "Country:": "دولة:",
          "Ingredients": "مكونات",
          "Steps": "خطوات",

  },
},
  };
  i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('language') || lang, // Default language
    keySeparator: false,
    interpolation: { escapeValue: false },
  });
  //States
  //Lightbox
  const [slides, setSlides] = useState([])
  const [initialSlide, setInitialSlide] = useState()
  const [open, setOpen] = useState(false)
  //Recipe info
  const [recipe, setRecipe] = useState(null)
  const [favBool, setfavBool] = useState(false)
  //User Info
    //is logged in?
    const [isLoggedin, setisLoggedin] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    //is premium?
    const [isPremium, setUserPremium] = useState(false)
    //is verified?
    const [verified, setVerified] = useState(false)
    //username
    const [username, setUsername] = useState('')
    //email
    const [userEmail, setUserEmail] = useState('')
    //Recipe result
    const [recipeFound, setRecipeFound] = useState(false)
  //#endregion




 

  //#region Functions
  //Language Set From Cookies
  const onLangClick = () => {
    if(lang == "en"){
      setLang("ar")
      Cookies.set('language', "ar", { expires: 365, path: '/' });
    }
    else{
      setLang("en")
      Cookies.set('language', "en", { expires: 365, path: '/' });
    } 
  }
  //Login
  const onLoginClick = () => {
    history.replace({
      pathname: '/login'
    })
  }
  //Subscribe
  const onSubscribeClick = () => {
    history.push({
      pathname: '/premium'
    })
  }
  //Logout
  const onLogout = async () => {
    await axios.post(`https://${serverIP}/api/logout`, {}, { withCredentials: true })
    .then(()=>{
      setisLoggedin(false)
      setShowAccount(false)
      setfavBool(false)
      setUserPremium(false)
      history.replace({
        pathname: '/'
      })
    })
    .catch(async error =>{
      if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
        await fetchCsrfToken(); // Refresh the token
        // Retry the request after refreshing the token
        onLogout();
      }else{
        recipeAlert({
          header: lang=='en'?"ُError!":"!خطأ",
          message:  lang=='en'?'An unexpected error Occured logging out!':'حدث خطأ غير متوقع أثناء تسجيل الخروج!',
          buttons: [lang=='en'?"Ok":"نعم"],
          cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
          mode: 'md',
        })
      }
    });
  }
  //send verify link
  const verifyUser = async () => {
    if(!userEmail){
      return
    }
    const result = {
        email: userEmail
    }
    try{
        axios.post(`https://${serverIP}/api/verifyRequest`,result,{withCredentials: true }).then(()=>{
          recipeAlert({
            header: lang=='en'?'Done!':"!انتهى",
            message:  lang=='en'?"If the email exists, a verification email has been sent.":'إذا كان البريد الإلكتروني موجودًا، فقد تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور.',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })
        })
    }catch(error){
        if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
          const trial = await fetchCsrfToken(); // Refresh the token
          if(!trial){
            recipeAlert({
              header: lang=='en'?"ُError!":"!خطأ",
              message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
              buttons: [lang=='en'?"Ok":"نعم"],
              cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
              mode: 'md',
            })  
          }else{
            // Retrysigning up
            verifyUser();
          }
        }
    }
  }
  //Set Favorite
  const stateFavourite = async () => {
    if(!isLoggedin){
      recipeAlert({
        header: lang=='en'?"Alert":"تنبيه",
        message: lang=='en' ? "You have to be Logged in to add to favorite": 'يجب عليك تسجيل الدخول لتتمكن من الإضافة إلى المفضلة',
        buttons: [
          {
            text: `${lang=='en' ? 'Cancel' : 'إلغاء'}`,
            role: 'cancel',
            handler: () => {
            // Handle cancel action
            }
          },
          {
            text: `${lang=='en' ? 'Login' : 'تسجيل الدخول'}`,
            handler: () => {
              history.push({
                pathname: '/login'
              })
            }
          }
          ],
        cssClass: lang=='en' ? 'home_alert' : 'home_alert home_alert_arabic'
      })
      return
    }
    if(!isPremium){
      recipeAlert({
        header: lang=='en'?"Alert":"تنبيه",
          message: lang=='en' ? "You have to be subscribed to add to favorite": 'يجب عليك أن تكون مشتركا للإضافة إلى المفضلة',
          buttons: [
            {
              text: `${lang=='en' ? 'Cancel' : 'إلغاء'}`,
              role: 'cancel',
              handler: () => {
              // Handle cancel action
              }
            },
            {
              text: `${lang=='en' ? 'subscribe' : 'إشتراك'}`,
              handler: () => {
                history.push({
                  pathname: '/premium'
                })
              }
            }
            ],
          cssClass: lang=='en' ? 'home_alert' : 'home_alert home_alert_arabic'
        })
        return
    }
    const result = {
      recipeid: recipe.rid
    }
    await axios.post(`https://${serverIP}/api/insertFavourite`, result,{ withCredentials: true })  
      .then(response => {
        setfavBool(true)
      })
      .catch(async error => {
        if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
          const trial = await fetchCsrfToken(); // Refresh the token
          if(!trial){
            recipeAlert({
              header: lang=='en'?"ُError!":"!خطأ",
              message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
              buttons: [lang=='en'?"Ok":"نعم"],
              cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
              mode: 'md',
            })  
          }else{
            // Retry the request after refreshing the token
            stateFavourite();
          }
        }else{
          recipeAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message:  lang=='en'?'An unexpected error Occured logging out!':'حدث خطأ غير متوقع أثناء تسجيل الخروج!',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })
        }
      })
  }
  //Remove Favorite
  const RevstateFavourite = async () => {
    if(!isLoggedin){
      recipeAlert({
        header: lang=='en'?"Alert":"تنبيه",
        message: lang=='en' ? "You have to be Logged in to add to favorite": 'يجب عليك تسجيل الدخول لتتمكن من الإضافة إلى المفضلة',
        buttons: [
          {
            text: `${lang=='en' ? 'Cancel' : 'إلغاء'}`,
            role: 'cancel',
            handler: () => {
            // Handle cancel action
            }
          },
          {
            text: `${lang=='en' ? 'Login' : 'تسجيل الدخول'}`,
            handler: () => {
              history.push({
                pathname: '/login'
              })
            }
          }
          ],
        cssClass: lang=='en' ? 'home_alert' : 'home_alert home_alert_arabic'
      })
      return
    }
    if(!isPremium){
      recipeAlert({
        header: lang=='en'?"Alert":"تنبيه",
          message: lang=='en' ? "You have to be subscribed to add to favorite": 'يجب عليك أن تكون مشتركا للإضافة إلى المفضلة',
          buttons: [
            {
              text: `${lang=='en' ? 'Cancel' : 'إلغاء'}`,
              role: 'cancel',
              handler: () => {
              // Handle cancel action
              }
            },
            {
              text: `${lang=='en' ? 'subscribe' : 'إشتراك'}`,
              handler: () => {
                history.push({
                  pathname: '/premium'
                })
              }
            }
            ],
          cssClass: lang=='en' ? 'home_alert' : 'home_alert home_alert_arabic'
        })
        return
    }
    const result = {
      recipeid: recipe.rid
    }
    await axios.post(`https://${serverIP}/api/deleteFavourite`, result,{ withCredentials: true })
        .then(response => {
          setfavBool(false)
        })
        .catch(async error => {
          if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
            const trial = await fetchCsrfToken(); // Refresh the token
            if(!trial){
              recipeAlert({
                header: lang=='en'?"ُError!":"!خطأ",
                message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
                buttons: [lang=='en'?"Ok":"نعم"],
                cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                mode: 'md',
              })  
            }else{
              // Retry the request after refreshing the token
              stateFavourite();
            }
          }else{
            recipeAlert({
              header: lang=='en'?"ُError!":"!خطأ",
              message:  lang=='en'?'An unexpected error Occured logging out!':'حدث خطأ غير متوقع أثناء تسجيل الخروج!',
              buttons: [lang=='en'?"Ok":"نعم"],
              cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
              mode: 'md',
            })
          }
        })
  }
  //Get Recipe Info
  const fetchRecipe = async () => {
    try {
      const recipeId = parseInt(id, 10);
      return await axios.get(`https://${serverIP}/api/getInfo?rid=${encodeURIComponent(recipeId)}`,{ withCredentials: true })
                .then((result) => {
                    console.log(result.data[0])
                    if(result.data==0){
                        return false
                    }
                    let reci = new Recipe(
                      0,
                      result.data[0].rid,
                      result.data[0].name,
                      result.data[0].title,
                      result.data[0].subtitle,
                      result.data[0].description,
                      result.data[0].steps,
                      result.data[0].images,
                      result.data[0].category,
                      result.data[0].visible,
                      result.data[0].ingredients,
                      result.data[0].time,
                      result.data[0].portion,
                      result.data[0].origin,
                      result.data[0].difficulty,
                      result.data[0].recipe_nameAr,
                      result.data[0].titleAr,
                      result.data[0].subtitleAr,
                      result.data[0].descriptionAr,
                      result.data[0].stepsAr,
                      result.data[0].ingredientsAr,
                      result.data[0].difficultyAr,
                      result.data[0].originAr,
                      result.data[0].nameAr,
                      result.data[0].premium,
                      )
                    setRecipe(reci.state)
                    console.log(result.data)
                    return true
                })
    } catch (error) {
      if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
        const trial = await fetchCsrfToken(); // Refresh the token
        if(!trial){
          recipeAlert({
            header: lang=='en'?"ُError!":"!خطأ",
            message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
            buttons: [lang=='en'?"Ok":"نعم"],
            cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
            mode: 'md',
          })  
          return false
        }else{
          // Retrysigning up
          return await fetchRecipe();
        }
      }else{
        return false
      }
      
    }
  };
  const isFavourite = async (rid) => {
    await axios.get(`https://${serverIP}/api/isfavourite?rid=${encodeURIComponent(rid)}`,{ withCredentials: true })
              .then((result) => {
                  if(result.data == 'no data'){
                      return
                  }
                  if(result.data){
                    setfavBool(result.data)
                  }
              }).catch(async (error) => {
                if (error.response?.status === 403 && error.response?.data?.error === 'Invalid CSRF token') {
                  const trial = await fetchCsrfToken(); // Refresh the token
                  if(!trial){
                    recipeAlert({
                      header: lang=='en'?"ُError!":"!خطأ",
                      message: lang=='en'?"Can't connect to the server! Please refresh.":'لا يمكن الاتصال بالخادم! يرجى تحديث الموقع.',
                      buttons: [lang=='en'?"Ok":"نعم"],
                      cssClass: lang=='en'?'form_alert':'form_alert form_alert_arabic',
                      mode: 'md',
                    })  
                  }else{
                    // Retrysigning up
                    isFavourite(rid)
                  }
                }
              });
  }
  //Image Slider
  const openbox = (i) =>{
    setOpen(true)
    setSlides(recipe.images.map(image => ({ src: image })));
    setInitialSlide(i)
  }

  //CSRF server Request
  const fetchCsrfToken = async () => {
    try {
      await axios.get(`https://${serverIP}/api/csrf-token`,{withCredentials: true}).then(()=>{
        return 1
      });
    } catch (error) {
      //Error Handling
      return 0
    }
  };
  //#endregion Functions

//#region use Effect
  useEffect(() => {
    
    setIsReady(false)
    setisLoading(true)

    //Access Token
    const intervalId = setInterval(() => {
      // Call the refresh token endpoint to renew the access token
      axios.post(`https://${serverIP}/api/refresh-authenticate`,{},{withCredentials: true })
        .then(response => {
          //Response
        })
        .catch(error => {
          //Error handling
        });
    }, 55 * 60 * 1000);  // Refresh the token every 55 minutes (before expiry)
      
    //Access Token Refresh
    const authenticateAPI = async () => {  
      await axios.post(`https://${serverIP}/api/authenticate`,{}, { withCredentials: true })
      .then(response => {
          //Response
      })
      .catch(error => {
          //Error Hanling
      });
    }

    //Check cookies for log in info
    const getProtectedResource = async () => {
      try {
        await axios.get(`https://${serverIP}/api/protected`, { withCredentials: true });
        setisLoggedin(true)
        setShowAccount(true)
        await axios.get(`https://${serverIP}/api/getUserInfo`, { withCredentials: true }).then((info)=>{
          if(info.data == 'no data'){
            return
          }
          console.log(info.data)
          setUserPremium(info.data.isPremium)
          setUsername(info.data.username)
          setUserEmail(info.data.email)
          setVerified(info.data.verified)
        });
      } catch (error) {
        if (error.response && error.response.status === 401) { // Auth Cookie not found
          try {
            await axios.post(`https://${serverIP}/api/refresh`, {}, { withCredentials: true });
            await axios.get(`https://${serverIP}/api/protected`,{ withCredentials: true });
            setisLoggedin(true)
            setShowAccount(true)
            await axios.get(`https://${serverIP}/api/getUserInfo`, { withCredentials: true }).then((info)=>{
              if(info.data == 'no data'){
                return
              }
              setUserPremium(info.data.isPremium)
              setUsername(info.data.username)
              setUserEmail(info.data.email)
              setVerified(info.data.verified)
            });
          } catch (refreshError) { // No refresh cookie found
            //Error handling
            return
          }
        }
      }
    };
   
    const loadData = async () => {
      await fetchCsrfToken()
      await authenticateAPI()
      await getProtectedResource()
      const result = await fetchRecipe()
      if(result){
        console.log("recipe exists")
        setRecipeFound(true)
        
      }
      setIsReady(true)
      setisLoading(false)
    }

    loadData()


    return () => {
      clearInterval(intervalId); 
    };
    
    
  }, []);

  useEffect(()=>{
    if(recipe){
      isFavourite(recipe.rid)
    }
  }, [recipe])
//#endregion



//#region UI
  return (
    <>
     {ready && !isLoading ? 
    
    <IonPage className='recipe_page'>
     
      <IonHeader class='recipe_header'>
          <NavBar username={username? username : ''} verified={verified} isSubscribed={isPremium} lang={lang} onLangClick={onLangClick} onLoginClick={onLoginClick} onSubscribeClick={onSubscribeClick} onVerify={verifyUser}  showAccount={showAccount} onLogout={onLogout} showLogin={!isLoggedin}></NavBar>
      </IonHeader>
      <div className='recipe_background'></div>
      <IonContent className='recipe_content'>
        <div className='recipe_background_img'></div>
       { recipeFound ? (

        (!recipe.isPremium || isPremium) ? (

        <div className='recipe_container'>


          {/* Recipe Introduction */}
          <div className='recipe_intro'>

            {/* Recipe Left Information (Image, cards) */}
            <div className="recipe_img_div">
              { recipe && Array.isArray(recipe.images) ?
                <img className="recipe_image" src={recipe.images[0]} ></img>
              :null}
              <div className='recipe_info_card' >
                <div className='recipe_info_title' >
                  <IonIcon className='recipe_info_card_icon' icon={timeOutline}></IonIcon>
                  <span className='recipe_info_card_info'>{t('Time')}</span>
                </div>
                <span className='recipe_info_card_value'>{recipe.time ? recipe.time: '-'}</span>
                </div>
              <div className='recipe_info_card'>
                <div className='recipe_info_title'>
                    <IonIcon className='recipe_info_card_icon' icon={peopleOutline}></IonIcon>
                    <span className='recipe_info_card_info'>{t('Portion')}</span>
                  </div>
                  <span className='recipe_info_card_value'>{recipe.portion ? recipe.portion: '-'}</span>
                </div>
              <div className='recipe_info_card'>
                <div className='recipe_info_title'>
                      <IonIcon className='recipe_info_card_icon' icon={speedometerOutline}></IonIcon>
                      <span className='recipe_info_card_info'>{t('Difficulty')}</span>
                    </div>
                    <span className='recipe_info_card_value'>{recipe.difficulty ? lang =="en" ? recipe.difficulty : recipe.difficultyAr : '-'}</span>
                </div>
            </div>


            {/* Recipe Right Information (description, tags) */}
            <div className='recipe_desc_div' style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}>
              <div className='recipe_desc_title'>
              <span>{lang=="en" ? recipe.name : recipe.nameAr}</span>
              {!favBool && (<img onClick={stateFavourite} className="recipe_desc_star" src={starOff}></img>)}
              {favBool && (<img onClick={RevstateFavourite} className="recipe_desc_star" src={starOn}></img>)} 
              </div>
              <span className='recipe_desc_subtitle'>{t('Subclass:')}  {lang=="en" ? recipe.subTitle : recipe.subTitleAr}</span>
              <span className='recipe_desc_category'>{t('Category:')}  {lang=="en" ? recipe.category : recipe.categoryAr}</span>
              <span className='recipe_desc_country'>{t('Country:')} {lang=="en" ? recipe.origin : recipe.originAr} </span>
              <span className='recipe_desc_description'>{lang=="en" ? recipe.description : recipe.descriptonAr}</span>
            </div>


          </div>

          

          
          {/* Ingredients */}
          <div className='recipe_ing_step_container'>

            <div className='recipe_ingredients' style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}>
            <div className='recipe_ing_title'>{t('Ingredients')}</div>
                <div className='recipe_ing_list'>
                  {lang=="en" ?
                recipe.ingredients && ready ? recipe.ingredients.map((r,i)=>{return(           
                  <span key={i} className='recipe_ing'>{'•     '+r}</span>           
                )}): null :
                recipe.ingredientsAr && ready ? recipe.ingredientsAr.map((r,i)=>{return(           
                  <span key={i} className='recipe_ing'>{'•     '+r}</span>           
                )}): null}
                </div>
              {/* </div> */}
            </div>


            {/* Steps */}
            <div className='recipe_steps' style={lang=='en'?{direction: "ltr", textAlign: "left"}:{direction: "rtl", textAlign: "right"}}>
            <div className='recipe_steps_title'>{t('Steps')}</div>         
              <div className='recipe_steps_list'>
              {lang=="en" ? recipe && Array.isArray(recipe.images) && ready ? recipe.steps.map((s,i)=> {return(             
                  <span key={i} className='recipe_step'>{(i+1)+')     '+s}</span>            
              )}) : null :
              recipe && Array.isArray(recipe.images) && ready ? recipe.stepsAr.map((s,i)=> {return(             
                <span key={i} className='recipe_step'>{(i+1)+')     '+s}</span>            
            )}) : null
              }
              </div>
            </div>
          
          </div>

          {/* Gallery */}
          <div className='gallery_container'>
              <div className="inner_gallery_container">
                { recipe && Array.isArray(recipe.images) && ready ? recipe.images.map((s,i) => {
                  if(i != 0)
                  return(
                    <div key={i} className="gallery_item">
                      <img onClick={() => openbox(i)} className="gallery_img" src={recipe.images[i]}></img>
                    </div>
                  )
                }) : null}                
              </div>
          </div>

          
           
              


        <Lightbox 
        open={open}
        plugins={[Counter, Fullscreen, Zoom, Thumbnails]}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        close={() => setOpen(false)}
        slides={slides}
        index={initialSlide}></Lightbox>

        
          {/* Opinion Box */}
          <div>
          <Opinion lang={lang}></Opinion>
          </div>


                  
          


          
        </div>
       ): (<div className='recipe_no_recipe'>{t('You need to be premium user!')}</div>)
       ) : (
        <div className='recipe_no_recipe'>{t('Recipe not found!')}</div>
       )}

        <Footer lang={lang}></Footer>

      </IonContent>
            
    </IonPage>
          
    :null
    }
</>
   
  )
//#endregion



}


export default RecipePage;